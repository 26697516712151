import { createTheme } from '@fluentui/react';

export default createTheme({
  palette: {
    themePrimary: '#3570e6',
    themeLighterAlt: '#f6f9fe',
    themeLighter: '#dce6fb',
    themeLight: '#bed1f7',
    themeTertiary: '#81a6f0',
    themeSecondary: '#4b80e9',
    themeDarkAlt: '#3065cf',
    themeDark: '#2855ae',
    themeDarker: '#1e3f81',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }
});