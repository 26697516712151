import * as React from 'react';

import ListingList from './listing-list';
import LocationSort from './location-sort';
import SpinnerView from './spinner-view';

import makeRequest from '../make-request';
import pushState from '../push-state';

import { mergeStyleSets, CommandBar, TextField, Stack, FocusZone, FocusZoneDirection, TooltipHost, IconButton, getTheme } from '@fluentui/react';

export default class BookSearch extends React.Component {
  constructor() {
    super();
    var theme = getTheme();
    this._styles = mergeStyleSets({
      outer: {
        position: 'absolute',
        top: 44,
        left: 0,
        margin: 0,
        padding: 0,
        width: '100%',
        height: 'calc(100% - 44px)'
      },
      bookInfo: {
        margin: 0,
        padding: 0,
        width: 'calc(100% - 10px)',
        paddingLeft: 10
      },
      list: {
        margin: 0,
        padding: 0,
        width: '100%',
      },
      bookTitle: [
        theme.fonts.large,
        {
          fontWeight: 'bold'
        }
      ],
      bookIsbn: [
        theme.fonts.small,
        {
          color: theme.palette.neutralTertiary,
          marginBottom: 10,
        }
      ]
    });
    this.state = {
      loading: false,
      searchIsbn: '',
      searchIsbnRequired: false,
      searchIsbnInvalid: false,
      locationSortVisible: false,
      listings: null
    }
    this._locationSortRef = React.createRef();
  }

  _isbnRegex = /^(97(8|9))?\d{10}$/g;

  _doSearch = () => {
    if (this.state.searchIsbn == null) {
      return this.setState({
        searchIsbnRequired: true
      });
    }
    var isbn = this.state.searchIsbn.replace(/[^\d]/g, '');
    if (isbn === '') {
      return this.setState({
        searchIsbnRequired: true
      });
    }
    if (!isbn.match(this._isbnRegex)) return;
    this.setState({
      loading: true
    }, () => {
      makeRequest('books.php?isbn=' + encodeURIComponent(isbn), 'GET').then((result) => {
        pushState('/books/' + encodeURIComponent(result.id));
        this.setState({
          loading: true,
          searchIsbn: '',
          searchIsbnInvalid: false,
          searchIsbnRequired: false
        });
      }).catch(() => {
        this.setState({
          loading: false,
          searchIsbnInvalid: true,
          searchIsbnRequired: false
        });
      });
    });
  }

  _renderInner = () => {
    if (this.props.book) {
      // WARNING: if this.props.book changes the change will not be detected!
      if (this.state.listings === null) {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.listings = this.props.book.listings;
      }
      
      return (
        <React.Fragment>
          <LocationSort
            ref={this._locationSortRef}
            visible={this.state.locationSortVisible}
            originalSort={this.props.book.listings}
            onDismiss={() => this.setState({
              locationSortVisible: false
            })}
            onNewSort={(listings) => {
              this.setState({
                listings
              });
            }}
          />
          <div className={this._styles.bookInfo}>
            <span className={this._styles.bookTitle}>{this.props.book.title}</span><br />
            <span className={this._styles.bookIsbn}>{this.props.book.isbn}</span>
          </div>
          <FocusZone direction={FocusZoneDirection.vertical} className={this._styles.list}>
            <ListingList items={this.state.listings} book={this.props.book} bookColumns={false} />
          </FocusZone>
        </React.Fragment>
      );
    } else {
      return (
        <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
          <Stack.Item order={1} align="stretch">
            <TextField label="Codice ISBN" defaultValue={this.state.searchIsbn} required
              onChange={(_e, value) => {
                this.setState({
                  searchIsbn: (value === '') ? null : value,
                  searchIsbnRequired: false,
                  searchIsbnInvalid: false
                });
              }}
              errorMessage={this.state.searchIsbnRequired ? 'Inserire un ISBN.' : (this.state.searchIsbnInvalid ? 'Non conosciamo questo ISBN.' : undefined)}
              onGetErrorMessage={(value) => {
                var cleanValue = value.replace(/[^\d]/g, '');
                if (cleanValue === '' || cleanValue === null) return;
                if (!cleanValue.match(this._isbnRegex)) return 'ISBN non valido.';
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this._doSearch();
                }
              }}
            />
          </Stack.Item>
          <Stack.Item order={3} align="end">
            <TooltipHost content="Cerca">
              <IconButton
                iconProps={{
                  iconName: 'Search'
                }}
                title="Cerca"
                ariaLabel="Cerca"
                onClick={this._doSearch}
              />
            </TooltipHost>
          </Stack.Item>
        </Stack>
      );
    }
  }

	render() {
    if (this.state.loading) {
      return (<SpinnerView />);
    }
    var commandBarItems = [
      {
        key: 'searchClassroom',
        text: 'Cerca per classe',
        iconProps: { iconName: 'Search' },
        href: '/',
        onClick: pushState.menuHandler
      }
    ];
    if (this.props.book != null) {
      commandBarItems.push({
        key: 'newSearch',
        text: 'Nuova ricerca',
        iconProps: { iconName: 'Switch' },
        href: '/books',
        onClick: pushState.menuHandler
      });
    }
		return (
      <React.Fragment>
        <CommandBar
          items={commandBarItems}
          farItems={this.props.book === null ? [] : [
            {
              key: 'locationToggle',
              text: 'Servizi di posizione',
              iconProps: { iconName: (this._locationSortRef.current !== null && this._locationSortRef.current.enabled) ? 'Streaming' : 'StreamingOff' },
              iconOnly: true,
              onClick: () => this.setState({
                locationSortVisible: true
              })
            },
            {
              key: 'createListing',
              text: 'Crea inserzione',
              iconProps: { iconName: 'Add' },
              iconOnly: true,
              onClick: this.props.onCreateListing
            },
          ]}
          ariaLabel="Usa le frecce sinistra e destra per navigare tra i comandi"
        />
        <div className={this._styles.outer}>
          {this._renderInner()}
        </div>
      </React.Fragment>
		);
	}
}
