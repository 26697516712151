import * as React from 'react';
import { createSvgIcon } from '@fluentui/react-icons-mdl2';

export const TelegramLogo = createSvgIcon({
  svg: ({ classes }) => (
    <svg viewBox="0,0,2048,2048" xmlns="http://www.w3.org/2000/svg" className={classes.svg} transform="translate(0 -3)" aria-hidden="true" focusable="false">
      <g transform="matrix(16,0,0,16,-69248,-128)">
        <path d="M4392,8C4375.03,8 4358.74,14.747 4346.75,26.745C4334.75,38.743 4328,55.033 4328,72C4328,88.967 4334.75,105.257 4346.75,117.255C4358.74,129.253 4375.03,136 4392,136C4408.97,136 4425.26,129.253 4437.25,117.255C4449.25,105.257 4456,88.967 4456,72C4456,55.033 4449.25,38.743 4437.25,26.745C4425.26,14.747 4408.97,8 4392,8ZM4392,13.208C4407.59,13.208 4422.55,19.406 4433.57,30.427C4433.57,30.427 4433.57,30.428 4433.57,30.428C4444.59,41.45 4450.79,56.414 4450.79,72C4450.79,87.586 4444.59,102.55 4433.57,113.572C4433.57,113.572 4433.57,113.573 4433.57,113.573C4422.55,124.594 4407.59,130.792 4392,130.792C4376.41,130.792 4361.45,124.594 4350.43,113.573C4350.43,113.573 4350.43,113.572 4350.43,113.572C4339.41,102.55 4333.21,87.586 4333.21,72C4333.21,56.414 4339.41,41.45 4350.43,30.428C4350.43,30.428 4350.43,30.427 4350.43,30.427C4361.45,19.406 4376.41,13.208 4392,13.208Z"/>
      </g>
      <g transform="matrix(16,0,0,16,-69248,-128)">
        <path d="M4356.97,71.324C4375.63,63.196 4388.07,57.837 4394.29,55.248C4412.07,47.855 4415.76,46.571 4418.17,46.528C4418.7,46.519 4419.88,46.65 4420.65,47.273C4421.29,47.798 4421.47,48.508 4421.56,49.006C4421.64,49.504 4421.75,50.639 4421.66,51.525C4420.7,61.645 4416.53,86.203 4414.41,97.538C4413.52,102.334 4411.75,103.942 4410.04,104.099C4406.32,104.441 4403.5,101.643 4399.9,99.284C4394.27,95.591 4391.09,93.293 4385.62,89.69C4379.3,85.526 4383.4,83.237 4387,79.497C4387.94,78.518 4404.32,63.623 4404.63,62.272C4404.67,62.103 4404.71,61.473 4404.33,61.141C4403.96,60.808 4403.41,60.922 4403.01,61.012C4402.44,61.14 4393.45,67.088 4376.01,78.855C4373.46,80.609 4371.15,81.464 4369.07,81.419C4366.79,81.37 4362.39,80.127 4359.12,79.065C4355.12,77.762 4351.93,77.073 4352.21,74.86C4352.35,73.708 4353.94,72.529 4356.97,71.324Z"/>
      </g>
    </svg>
  ),
  displayName: 'TelegramLogo'
});