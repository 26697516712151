import * as React from 'react';

import SpinnerView from './spinner-view';

import config from '../config';
import makeRequest from '../make-request';

import locations from '../locations/locations.json';
import locationIndexes from '../locations/indexes.json';

import { Panel, TextField, DefaultButton, PrimaryButton, ActionButton, Separator, Text, TagPicker, Checkbox, Link, TooltipHost } from '@fluentui/react';
import Badge from './badge';
import ensureScript from '../ensure-script';

export default class AccountManager extends React.Component {
  constructor() {
    super();
    this.locations = [];
    for (var location of locations) {
      this.locations.push({
        key: location.i,
        name: location.n + ' (' + location.p + ')'
      });
    }
    this._closedState = {
      loading: true,
      name: null,
      email: null,
      showEmail: false,
      phone: null,
      telegram: false,
      location: null,
      badge: null,
      confirmingDelete: false,
      linkingTelegram: false
    }
    this.state = this._closedState;
    this._previouslyVisible = false;
  }

  // eslint-disable-next-line no-control-regex
  _emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g;

  _phoneRegex = /^3[0-9]{8,9}$/g;

  /**
   * Save account changes.
   * @param {object | undefined | null} saveObj Data to save. If not an object data will be saved from current state.
   * @param {function?} rejectHandler Function to call if saving fails. Return a falsey value to use default rejection handling (consider failure as token expiration).
   */
  _saveAccount = (saveObj, rejectHandler) => {
    if (typeof saveObj !== 'object' || saveObj === null) {
      if (this.state.name === '' || this.state.name === null) return;
      if (this.state.email === '' || this.state.email === null) return;
      if (!this.state.email.match(this._emailRegex)) return 'Indirizzo email non valido';
      var cleanPhone = '';
      if (this.state.phone != null) {
        cleanPhone = this.state.phone.replace(/[^\d]/g, '');
        if (!cleanPhone.match(this._phoneRegex)) return;
      }
      saveObj = {
        name: this.state.name,
        email: this.state.email,
        show_email: this.state.showEmail,
        phone: cleanPhone,
        location: (this.state.location == null) ? '' : this.state.location.key
      };
    }
    this.setState({
      loading: true
    }, () => {
      makeRequest('profile.php', 'POST', this.props.token, new URLSearchParams(saveObj)).then((data) => {
        config.runtime.users[data.id] = data;
        var location = null;
        if (data.location !== '' && data.location !== null) {
          var intKey = parseInt(data.location);
          if (!isNaN(intKey)) {
            var i = locationIndexes.indexOf(intKey);
            if (i !== -1) {
              location = this.locations[i];
            }
          }
        }
        this.setState({
          loading: false,
          linkingTelegram: false,
          name: data.name,
          email: data.email,
          showEmail: data.showEmail,
          phone: data.phone,
          telegram: data.telegram,
          badge: data.badge,
          location
        }, () => {
          this.props.onEmailChange(this.state.email)
        });
      }).catch((e) => {
        if (typeof rejectHandler !== 'function' || !rejectHandler(e)) {
          this.props.onAccountExpired();
        }
      });
    });
  }

  _renderDataMgr = () => {
    if (this.state.dataMgrVisible) {
      return (
        <ActionButton
          iconProps={{
            iconName: 'Database'
          }}
          onClick={() => {
            window.open(config.baseUrl + 'datamgr.php');
          }}
        >Gestione dati</ActionButton>
      );
    } else {
      return null;
    }
  }

  _renderInner = () => {
    if (this.state.loading) {
      return (
        <SpinnerView />
      );
    } else if (this.state.confirmingDelete) {
      return (
        <React.Fragment>
          <p>
            <Text variant="medium">Sei sicuro di voler cancellare il tuo account?</Text>
            <br />
            <Text variant="medium">Attenzione: verranno cancellate anche tutte le tue inserzioni!</Text>
            <br />
            <Text variant="medium">Se hai un badge attivo sul tuo account, anche questo verrà perso.</Text>
          </p>
          <ActionButton
              iconProps={{
                iconName: 'Delete'
              }}
              onClick={() => {
                this.setState({
                  loading: true
                }, () => {
                  makeRequest('profile.php', 'DELETE', this.props.token).then(() => {
                    this.props.onRequestReload();
                    this.props.onAccountExpired();
                  }).catch(() => {
                    this.props.onAccountExpired();
                  });
                });
              }}
            >Cancella account</ActionButton>
          <ActionButton
            iconProps={{
              iconName: 'Blocked'
            }}
            onClick={() => {
              this.setState({
                confirmingDelete: false
              });
            }}
          >Annulla</ActionButton>
        </React.Fragment>
      );
    } else if (this.state.linkingTelegram) {
      return (
        <React.Fragment>
          {this.state.linkingTelegram === 'message_send_failed' && <p><Text variant="medium"><b>Collegamento non riuscito. È necessario consentire al bot di inviarti messaggi.</b></Text></p>}
          <p><Text variant="medium">Puoi collegare un account Telegram per fornire un metodo alternativo di contatto che non richiede lo scambio di identificativi quali indirizzo email o numero di telefono.</Text></p>
          <p><Text variant="medium">È necessario consentire al bot di inviarti messaggi per permettere a quest'ultimo di menzionare il tuo account in messaggi inviati a chi desidera contattarti, consentendo a questi ultimi di inviarti un messaggio.<br />Il bot non ti invierà messaggi se non in risposta ad un tuo messaggio o per notificarti di un nuovo contatto.</Text></p>
          <p><Text variant="medium">Il collegamento di account Telegram è sperimentale e potrebbe presentare alcune imperfezioni. Per favore, segnala eventuali problemi <Link href="https://go.marmaluot.com/liberfeedback" target="_blank" rel="noopener noreferrer">inviando feedback</Link>.</Text></p>
          <ActionButton
              iconProps={{
                iconName: 'Link'
              }}
              onClick={() => {
                this.setState({
                  loading: true
                });
                window.Telegram.Login.auth({
                  bot_id: config.server.telegramBotId,
                  request_access: 'write',
                  lang: 'it'
                }, (data) => {
                  if (data === false) {
                    this.setState({
                      loading: false
                    });
                  } else {
                    this._saveAccount({
                      telegram: JSON.stringify(data)
                    }, (e) => {
                      // handle message_send_failed error (user did not allow bot to send messages)
                      if (
                        typeof e === 'object' &&
                        typeof e.error === 'string' &&
                        typeof e.errorDescription === 'string' &&
                        e.error === 'invalid_request' &&
                        e.errorDescription === 'message_send_failed'
                      ) {
                        this.setState({
                          loading: false,
                          linkingTelegram: 'message_send_failed'
                        });
                        return true;
                      } else {
                        // default error handling for any error other than the one that is explicitly handled here
                        return false;
                      }
                    });
                  }
                })
              }}
            >Avvia collegamento</ActionButton>
          <ActionButton
            iconProps={{
              iconName: 'Blocked'
            }}
            onClick={() => {
              this.setState({
                linkingTelegram: false
              });
            }}
          >Annulla</ActionButton>
        </React.Fragment>
      );
    } else {
      let canHideEmail = true;
      if ((this.state.phone === null || !this.state.phone.match(this._phoneRegex)) && !this.state.telegram) {
        canHideEmail = false;
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.showEmail = true;
      }
      return (
        <React.Fragment>
          <TextField label="Nome" defaultValue={this.state.name} required
            onChange={(_e, value) => {
              this.setState({
                name: (value === '') ? null : value
              });
            }}
            onGetErrorMessage={(value) => {
              if (value === '' || value === null) return 'Questo campo è obbligatorio.';
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this._saveAccount();
              }
            }}
          />
          <TextField label="Indirizzo email" defaultValue={this.state.email} required
            onChange={(_e, value) => {
              this.setState({
                email: (value === '') ? null : value
              });
            }}
            onGetErrorMessage={(value) => {
              if (value === '' || value === null) return 'Questo campo è obbligatorio.';
              if (!value.match(this._emailRegex)) return 'Indirizzo email non valido';
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this._saveAccount();
              }
            }}
          />
          <TextField label="Cellulare" defaultValue={this.state.phone || ''}
            onChange={(_e, value) => {
              this.setState({
                phone: (value === '') ? null : value
              });
            }}
            onGetErrorMessage={(value) => {
              var cleanValue = value.replace(/[^\d]/g, '');
              if (cleanValue === '' || cleanValue === null) return;
              if (!cleanValue.match(this._phoneRegex)) return 'Inserire un numero di telefono cellulare italiano, senza prefisso internazionale.';
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this._saveAccount();
              }
            }}
          />
          <DefaultButton
            text={this.state.telegram ? 'Scollega account Telegram' : 'Collega account Telegram'}
            iconProps={{
              iconName: 'TelegramLogo'
            }}
            style={{
              width: '100%',
              marginTop: '10px',
              marginBottom: '10px'
            }}
            onClick={() => {
              this.setState({
                loading: true
              }, () => {
                if (this.state.telegram) {
                  this._saveAccount({
                    telegram: ''
                  });
                } else {
                  ensureScript('https://telegram.org/js/telegram-widget.js').then(() => {
                    this.setState({
                      loading: false,
                      linkingTelegram: true
                    })
                  });
                }
              })
            }}
          />
          <label htmlFor="account-manager-locationpicker">Luogo</label>
          <TagPicker
            removeButtonAriaLabel="Rimuovi"
            selectionAriaLabel="Comune selezionato"
            onResolveSuggestions={(filterText) => {
              if (filterText.length < 3) return [];
              var lowerFilterText = filterText.toLowerCase();
              var results = filterText
              ? this.locations.filter(
                  tag => tag.name.toLowerCase().indexOf(lowerFilterText) === 0
                )
              : [];
              return results;
            }}
            getTextFromItem={(item) => item.name}
            pickerSuggestionsProps={{
              suggestionsHeaderText: 'Seleziona comune',
              noResultsFoundText: 'Nessun risultato (scrivi almeno 3 caratteri)',
            }}
            selectedItems={(this.state.location === null) ? [] : [this.state.location]}
            onItemSelected={(a, b) => {
              this.setState({
                location: a
              });
            }}
            onChange={(a) => {
              if (a.length === 0) this.setState({
                location: null
              });
            }}
            itemLimit={1}
            inputProps={{
              id: 'account-manager-locationpicker',
            }}
          />
          <p><TooltipHost content="Devi avere almeno un metodo di contatto alternativo (telefono o Telegram) per poter nascondere il tuo indirizzo email.">
            <Checkbox label="Mostra indirizzo email sulle mie inserzioni" disabled={!canHideEmail} checked={this.state.showEmail} onChange={() => {
              this.setState({
                showEmail: !this.state.showEmail
              });
            }} />
          </TooltipHost></p>
          <p><Text variant="medium"><b>Badge:</b></Text> {this.state.badge === null ? <Text variant="medium">Nessuno</Text> : <Badge name={this.state.badge} />}</p>
          <Text variant="medium"><p>Attenzione! Assicurati di aver scritto correttamente il tuo indirizzo email, altrimenti non potrai più accedere a Liber!</p></Text>
          <DefaultButton text="Annulla" onClick={this.props.onClose} />
          <PrimaryButton text="Salva" onClick={() => this._saveAccount()} />
          <Separator />
          <ActionButton
            iconProps={{
              iconName: 'PageHeaderEdit'
            }}
            onClick={this.props.onListingManagerActivate}
          >Gestione inserzioni</ActionButton>
          <ActionButton
            iconProps={{
              iconName: 'Leave'
            }}
            onClick={() => {
              this.setState({
                loading: true
              }, () => {
                makeRequest('logout.php', 'POST', this.props.token).then(() => {
                  this.props.onAccountExpired();
                }).catch(() => {
                  this.props.onAccountExpired();
                });
              });
            }}
          >Esci</ActionButton>
          <ActionButton
            iconProps={{
              iconName: 'Delete'
            }}
            onClick={() => {
              this.setState({
                confirmingDelete: true
              });
            }}
          >Cancella account</ActionButton>
          <ActionButton
            iconProps={{
              iconName: 'DownloadDocument'
            }}
            onClick={() => {
              this.setState({
                loading: true
              }, () => {
                makeRequest('data_export.php', 'GET', this.props.token).then((data) => {
                  this.setState({
                    loading: false
                  }, () => {
                    var dlBlob = new Blob([JSON.stringify(data)], {
                      type: 'application/json'
                    });
                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(dlBlob);
                    a.download = data.profile.id + '.json';
                    a.click();
                  });
                }).catch(() => {
                  this.props.onAccountExpired();
                });
              });
            }}
          >Scarica dati</ActionButton>
          <ActionButton
            iconProps={{
              iconName: 'RemoveLink'
            }}
            onClick={() => {
              this.setState({
                loading: true
              }, () => {
                makeRequest('logout.php?all', 'POST', this.props.token).then(() => {
                  this.props.onAccountExpired();
                }).catch(() => {
                  this.props.onAccountExpired();
                });
              });
            }}
          >Esci da tutti i dispositivi</ActionButton>
          {this._renderDataMgr()}
        </React.Fragment>
      );
    }
  }

	render() {
    if (this.props.visible === false && this._previouslyVisible) {
      this.setState(this._closedState);
    }
    if (this.props.visible && !this._previouslyVisible) {
      makeRequest('profile.php', 'GET', this.props.token).then((user) => {
        config.runtime.users[user.id] = user;
        var location = null;
        if (user.location !== '' && user.location !== null) {
          var intKey = parseInt(user.location);
          if (!isNaN(intKey)) {
            var i = locationIndexes.indexOf(intKey);
            if (i !== -1) {
              location = this.locations[i];
            }
          }
        }
        this.setState({
          loading: false,
          name: user.name,
          email: user.email,
          showEmail: user.showEmail,
          phone: user.phone,
          telegram: user.telegram,
          badge: user.badge,
          location,
          dataMgrVisible: user.dataMgr
        }, () => {
          this.props.onEmailChange(this.state.email)
        });
      }).catch(() => {
        this.props.onAccountExpired();
      });
    }
    this._previouslyVisible = this.props.visible;
		return (
      <Panel
        isOpen={this.props.visible}
        onDismiss={(e) => {
          if (this.state.loading) return e.preventDefault();
          this.props.onClose();
        }}
        closeButtonAriaLabel="Chiudi"
        headerText="Gestione account"
        isLightDismiss={!this.state.loading}
        hasCloseButton={!this.state.loading}
      >
        {this._renderInner(this.props)}
      </Panel>
		);
	}
}
