import * as React from 'react';

import { Dialog, DialogFooter, DialogType, PrimaryButton, DefaultButton, Spinner, SpinnerSize, Text, Link } from '@fluentui/react';

import config from '../config';

export default class ErrorHandler extends React.Component {
  constructor() {
		super();
    this.state = {
      report: null,
      // 0 = not sent, 1 = sending, 2 = sent, 3 = send error
      sendStatus: 0,
    }
  }

  _errorReplacer = (_key, value) => {
    if (value instanceof Error) {
      var error = {};
      Object.getOwnPropertyNames(value).forEach(function(errorKey) {
        error[errorKey] = value[errorKey];
      });
      return error;
    }
    return value;
  }

  _handleError = (e) => {
    console.error(e.error);
    this.setState({
      report: JSON.stringify({
        promise: false,
        error: e.error,
        message: e.message,
        source: e.source,
        pos: e.lineno + ':' + e.colno,
        agent: window.navigator.userAgent
      }, this._errorReplacer),
      sendStatus: 0
    });
  }

  _handleAsyncError = (e) => {
    console.error(e.reason);
    this.setState({
      report: JSON.stringify({
        promise: true,
        error: e.reason,
        agent: window.navigator.userAgent
      }, this._errorReplacer),
      sendStatus: 0
    });
  }

  _refresh = () => {
    window.location.search = '';
  }

  componentDidMount = () => {
    window.addEventListener('error', this._handleError);
    window.addEventListener('unhandledrejection', this._handleAsyncError);
	}
	
  componentWillUnmount = () => {
    window.removeEventListener('error', this._handleError);
    window.removeEventListener('unhandledrejection', this._handleAsyncError);
	}

  _renderCenter = () => {
    if (this.state.sendStatus === 0) {
      return (
        <>
          <Text variant="medium">Se desideri, puoi segnalare questo errore.</Text><br />
          <Text variant="small">Inviando una segnalazione, vengono salvati i seguenti dati: contenuto della segnalazione, data di invio, applicazione che ha generato la segnalazione (Liber), ID della segnalazione, e indirizzo IP del mittente. La segnalazione contiene dettagli sull'errore e la stringa User-Agent del proprio browser.</Text>
        </>
      );
    } else if (this.state.sendStatus === 1) {
      return (
        <>
          <Text variant="medium">Invio della segnalazione in corso...</Text><br />
          <Text variant="small">Inviando una segnalazione, vengono salvati i seguenti dati: contenuto della segnalazione, data di invio, applicazione che ha generato la segnalazione (Liber), ID della segnalazione, e indirizzo IP del mittente. La segnalazione contiene dettagli sull'errore e la stringa User-Agent del proprio browser.</Text>
        </>
      );
    } else if (this.state.sendStatus === 2) {
      return (
        <>
          <Text variant="medium">Segnalazione inviata. Grazie!</Text><br />
          <Text variant="small">Inviando una segnalazione, vengono salvati i seguenti dati: contenuto della segnalazione, data di invio, applicazione che ha generato la segnalazione (Liber), ID della segnalazione, e indirizzo IP del mittente. La segnalazione contiene dettagli sull'errore e la stringa User-Agent del proprio browser.</Text>
        </>
      );
    } else if (this.state.sendStatus === 3) {
      return (
        <>
          <Text variant="medium">È avvenuto un errore nell'invio della segnalazione. Se lo desideri, puoi scaricare il report e inviarlo al seguente indirizzo email: <Link href="mailto:hello@qlcvea.com">hello@qlcvea.com</Link>. Grazie.</Text><br />
          <Text variant="small">Inviando una segnalazione, vengono salvati i seguenti dati: contenuto della segnalazione, data di invio, applicazione che ha generato la segnalazione (Liber), ID della segnalazione, e indirizzo IP del mittente. La segnalazione contiene dettagli sull'errore e la stringa User-Agent del proprio browser.</Text>
        </>
      );
    }
  }

	render() {
    return (
      <Dialog
        hidden={this.state.report === null}
        onDismiss={this._refresh}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Errore',
          subText: 'È avvenuto un errore inaspettato!'
        }}
      >
        {this._renderCenter()}
        <DialogFooter>
          <DefaultButton onClick={() => {
            var blob = new Blob([this.state.report], {type: 'application/json'});
            var a = window.document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = 'mmlobug.liber.' + new Date().toISOString().replace(/:/g, '') + '.json'
            window.document.body.appendChild(a);
            a.click();
            window.document.body.removeChild(a);
          }} text="Salva report" />
          <DefaultButton onClick={this._refresh} text="Riavvia" />
          <PrimaryButton
            disabled={this.state.sendStatus !== 0}
            onClick={() => {
              this.setState({
                sendStatus: 1
              }, () => {
                fetch(config.bugsUrl, {
                  headers: new Headers({
                    'Content-Type': 'application/json'
                  }),
                  method: 'POST',
                  mode: 'cors',
                  referrerPolicy: 'origin-when-cross-origin',
                  body: this.state.report
                }).then((response) => {
                  response.json().then((data) => {
                    this.setState({
                      report: JSON.stringify(data),
                      sendStatus: 2
                    });
                  }).catch(() => {
                    this.setState({
                      sendStatus: 3
                    });
                  });
                }).catch(() => {
                  this.setState({
                    sendStatus: 3
                  });
                });
              });
            }}
          >Invia segnalazione {(this.state.sendStatus === 1 ? (<Spinner size={SpinnerSize.small} />) : null)}</PrimaryButton>
        </DialogFooter>
      </Dialog>
    );
	}
}
