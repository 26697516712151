import config from './config';

export default function ensureScript(url) {
  return new Promise((resolve, reject) => {
    try {
      if (config.runtime.addedScripts[url]) return resolve();

      const script = document.createElement('script');
      script.src = url;
      script.addEventListener('load', () => {
        config.runtime.addedScripts[url] = true;
        resolve();
      });
      script.addEventListener('error', () => {
        document.body.removeChild(script);
        reject(new Error(`Script ${url} failed to load`));
      });
      document.body.appendChild(script);
    } catch (err) {
      reject(err);
    }
  });
}