import * as React from 'react';

import { mergeStyleSets, getTheme, Persona, IconButton } from '@fluentui/react';
import Identicon from 'identicon.js';
import anonUserIcon from '../anon-user.svg';

import pushState from '../push-state';

export default class Header extends React.Component {
  constructor() {
    super();
    var theme = getTheme();
    this.state = {
      email: null,
      identiconHash: null
    }
    this._styles = mergeStyleSets({
      outer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        height: 50,
        width: '100%',
        backgroundColor: theme.semanticColors.primaryButtonBackground
      },
      text: {
        fontFamily: 'sans-serif',
        fontSize: theme.fonts.medium.fontSize,
        color: theme.semanticColors.primaryButtonText,
        fontWeight: 'bold',
        cursor: 'pointer',
        userSelect: 'none',
        textDecoration: 'none',
        paddingLeft: 20
      },
      infoButton: {
        position: 'absolute',
        top: 0,
        right: 50,
        width: 50,
        height: 50,
        cursor: 'pointer',
        color: theme.semanticColors.primaryButtonText,
        fontSize: '40px',
        selectors: {
          ':hover': {
            color: theme.semanticColors.primaryButtonTextHovered,
            backgroundColor: theme.semanticColors.primaryButtonBackgroundHovered
          },
          ':active': {
            color: theme.semanticColors.primaryButtonTextPressed,
            backgroundColor: theme.semanticColors.primaryButtonBackgroundPressed
          }
        }
      },
      accountImage: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 50,
        height: 50,
        cursor: 'pointer',
        selectors: {
          ':hover': {
            backgroundColor: theme.semanticColors.primaryButtonBackgroundHovered
          }
        }
      }
    });
  }

	render() {
    if (this.props.email !== this.state.email) {
      if (typeof this.props.email !== 'string') {
        // always delete identicon if new email is null
        if (typeof this.props.email !== 'string') {
          this.setState({
            email: null,
            identiconHash: null
          });
        }
      } else if (this.state.identiconHash !== false) {
        // generate identicon if not already being generated
        this.setState({
          identiconHash: false
        }, () => {
          crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(this.props.email)).then((hash) => {
            // generation may have been canceled (see previous if case)
            if (this.state.identiconHash === false) {
              this.setState({
                email: this.props.email,
                identiconHash: [...new Uint8Array(hash)].map(x => x.toString(16).padStart(2, '0')).join('')
              });
            }
          })
        });
      }
    }
		return (
			<div className={this._styles.outer}>
				<span><a className={this._styles.text} href="/" onClick={pushState.linkHandler}>Liber</a></span>
        <IconButton
          className={this._styles.infoButton}
          menuProps={{
            onItemClick: pushState.menuHandler,
            items: [
              {
                key: 'feedback',
                text: 'Invia feedback',
                iconProps: { iconName: 'Feedback' },
                target: '_blank',
                href: 'https://go.marmaluot.com/liberfeedback'
              },
              {
                key: 'videos',
                text: 'Videoguide',
                iconProps: { iconName: 'Play' },
                target: '_blank',
                href: 'https://go.marmaluot.com/liberyt'
              },
              {
                key: 'about',
                text: 'Lascia una mancia',
                iconProps: { iconName: 'Emoji2' },
                target: '_blank',
                href: 'https://qlcvea.it/tipjar'
              },
              {
                key: 'about',
                text: 'Informazioni su Liber',
                iconProps: { iconName: 'Info' },
                href: '/about'
              },
              {
                key: 'privacy',
                text: 'Info privacy e cookie',
                iconProps: { iconName: 'Shield' },
                href: '/privacy'
              },
            ],
            directionalHintFixed: true
          }}
          iconProps={{
            iconName: 'Info2',
            imageProps: {
              maximizeFrame: true,
              width: 45
            }
          }}
          title="Informazioni &amp; privacy"
          ariaLabel="Informazioni &amp; privacy"
          onRenderMenuIcon={() => {}}
        />
        <Persona
          className={this._styles.accountImage}
          imageUrl={(typeof this.state.identiconHash == 'string' && this.state.identiconHash) ? ('data:image/svg+xml;base64,' + new Identicon(this.state.identiconHash, { size: 50, format: 'svg' }).toString()) : anonUserIcon}
          hidePersonaDetails={true}
          imageAlt={(typeof this.props.email == 'string' && this.props.email) ? 'Gestione account' : 'Accedi'}
          onClick={() => {
            if (!this.props.profileEnabled) return;
            if (typeof this.props.email == 'string' && this.props.email) {
              if (typeof this.props.onAccountClick == 'function') {
                return this.props.onAccountClick();
              }
            } else {
              this.props.onLogin();
            }
          }}
        />
			</div>
		);
	}
}
