import * as React from 'react';

import pushState from '../push-state';

import { mergeStyleSets, Text, Link } from '@fluentui/react';

export default class Privacy extends React.Component {
  constructor() {
    super();
    this._styles = mergeStyleSets({
      outer: {
        maxWidth: 800,
        margin: 'auto',
        padding: '0 5px'
      },
      topSpacer: {
        marginTop: 30,
        marginBottom: 0
      }
    });
  }

	render() {
		return (
			<div className={this._styles.outer}>
        <div className={this._styles.topSpacer}></div>
				<Text variant="large"><h1>Informazioni privacy</h1></Text>
        <Text variant="medium">
          <p>Le informazioni seguenti si applicano solo a <i>marmaluot Liber</i> ("questo sito"). Alcuni servizi di terze parti da questo utilizzati (elencati in fondo a questa pagina) potrebbero raccogliere altri dati o conservarli per tempi più lunghi. Queste informazioni non si applicano ad eventuali link a siti esterni (non appartenenti a marmaluot.com o suoi sottodomini o a donwana.altervista.org).</p>
        </Text>
        <Text variant="mediumPlus"><h2>Ricezione dei dati</h2></Text>
        <Text variant="medium">
          <p>Quando Liber riceve dei dati, questi passano necessariamente attraverso i servizi di terze parti Altervista (dove sono conservati i dati dell'applicazione e dove è ospitato il lato "backend") e Cloudflare (che protegge l'applicazione su Altervista).</p>
          <p>Quando in questa pagina viene indicata "raccolta di dati da parte di Liber", "trasmissione di dati a Liber", o altri termini simili, si intende quanto sopra.</p>
        </Text>
        <Text variant="mediumPlus"><h2>Quali dati vengono raccolti?</h2></Text>
        <Text variant="medium">
          <h3>Tutti i visitatori</h3>
          <p>Questo sito raccoglie dai visitatori solo le informazioni necessarie per mostrare un elenco di inserzioni:</p>
          <ul>
            <li>istituto scelto</li>
            <li>indirizzo (scolastico) scelto</li>
            <li>classe scelta</li>
            <li>ISBN e/o ID interno del libro cercato</li>
          </ul>
          <p>Queste informazioni non vengono salvate permanentemente.</p>
          <p>Questo sito utilizza un servizio di terze parti (Plausible Analytics) per raccogliere informazioni su come viene utilizzato Liber. Questo servizio di terze parti raccoglie solo dati anonimi, non raccoglie dati personali.</p>
          <h3>Visitatori che hanno un account / che eseguono l'accesso</h3>
          <p>Questo sito raccoglie e conserva le seguenti informazioni dai visitatori che eseguono l'accesso e quindi creano un account su questo sito:</p>
          <ul>
            <li>nome completo</li>
            <li>indirizzo email</li>
            <li>numero di telefono (opzionale)</li>
            <li>ID account Telegram (opzionale; solo se quest'ultimo è stato collegato)</li>
            <li>comune (opzionale; il dato è conservato in una modalità "elaborabile" per permettere alla funzione "Trova libri vicino a te" di ordinare le inserzioni in base al luogo)</li>
            <li>badge attivo</li>
            <li>preferenza di visualizzazione pubblica indirizzo email (mostrato/nascosto)</li>
          </ul>
          <p>Per cancellare i dati opzionali, è sufficiente svuotare i campi corrispondenti nella gestione account e salvare i cambiamenti.<br />Per cancellare l'ID Telegram, scollegare l'account Telegram.</p>
          <p>Non è possibile cancellare il badge senza cancellare l'intero account.</p>
          <p>Gli altri dati sono obbligatori per avere un account su questo sito. Per cancellarli, è necessario cancellare il proprio account.</p>
          <p>Per ogni inserzione creata, vengono raccolti e conservati questi dati:</p>
          <ul>
            <li>identificativo univoco del libro (non è l'ISBN, è un ID specifico del sistema interno di questo sito)</li>
            <li>identificativo univoco dell'utente che ha creato l'inserzione (per mostrare i dati dell'utente insieme all'inserzione)</li>
            <li>tipologia (offerta/regalo)</li>
            <li>data di creazione dell'inserzione (utilizzata per ordinare gli elenchi di inserzioni)</li>
            <li>data di scadenza dell'inserzione</li>
          </ul>
          <p>Essendo Liber una bacheca il cui obiettivo principale è connettere persone per scambiare libri di testo, <b>tutte queste informazioni (raccolte e conservate per gli utenti che eseguono l'accesso) sono da considerarsi PUBBLICHE</b> (con l'eccezione dell'indirizzo email, se è stata disattivata l'opzione "Mostra indirizzo email sulle mie inserzioni")<b>.<br />Non inserire su questo sito informazioni che non avresti fornito se questo sito fosse una bacheca appesa fuori dalla scuola.</b></p>
          <p>L'opzione "Mostra indirizzo email sulle mie inserzioni" non può essere disattivata se non sono presenti altri metodi di contatto nell'account (telefono o account Telegram collegato). Se mentre l'opzione è disattivata vengono rimossi tutti gli altri metodi di contatto dall'account l'opzione verrà riattivata automaticamente.</p>
        </Text>
        <Text variant="mediumPlus"><h2>Come posso cancellare le mie informazioni?</h2></Text>
        <Text variant="medium">
          <h3>Tutti i visitatori</h3>
          <p>Questo sito non conserva permanentemente dati degli utenti che non eseguono l'accesso.</p>
          <p>Per richiedere la cancellazione dei propri dati da parte di servizi di terze parti utilizzati da questo sito, si consultino le loro privacy policy.</p>
          <p>È possibile cancellare i dati salvati sul proprio dispositivo dalle impostazioni del proprio browser.</p>
          <p>Liber raccoglie alcuni dati anonimi per ragioni statistiche che non possono essere rimossi. Maggiori informazioni nella sezione "Statistiche" di questa pagina.</p>
          <p>I dati salvati sul proprio dispositivo non verranno cancellati. È possibile cancellare questi dati dalle impostazioni del proprio browser.</p>
          <h3>Visitatori che hanno un account / che eseguono l'accesso</h3>
          <p>È possibile cancellare il proprio account in qualunque momento dalla schermata di gestione account (immagine di profilo in alto a destra), cliccando su "Cancella account".</p>
          <p>
            Le inserzioni scadute diventano immediatamente invisibili. Potrebbero essere necessarie alcune ore prima che vengano cancellate definitivamente.<br />
            Durante questa fase (invisibili ma non cancellate) le inserzioni scadute saranno ancora visibili all'interno dell'esportazione dei dati disponibile nel menu di gestione account.
          </p>
        </Text>
        <Text variant="mediumPlus"><h2>Come posso esportare i miei dati?</h2></Text>
        <Text variant="medium">
          <h3>Tutti i visitatori</h3>
          <p>Questo sito non conserva permanentemente dati degli utenti che non eseguono l'accesso.</p>
          <p>Per richiedere i propri dati salvati su servizi di terze parti utilizzati da questo sito, si consultino le loro privacy policy.</p>
          <h3>Visitatori che hanno un account / che eseguono l'accesso</h3>
          <p>È possibile esportate i propri dati in qualunque momento dalla schermata di gestione account (immagine di profilo in alto a destra), cliccando su "Scarica dati".</p>
          <p>
            Le inserzioni scadute diventano immediatamente invisibili. Potrebbero essere necessarie alcune ore prima che vengano cancellate definitivamente.<br />
            Durante questa fase (invisibili ma non cancellate) le inserzioni scadute saranno ancora visibili all'interno dell'esportazione dei dati disponibile nel menu di gestione account.
          </p>
        </Text>
        <Text variant="mediumPlus"><h2>Interruzione del servizio</h2></Text>
        <Text variant="medium">
          <p><i>marmaluot Liber</i> è disponibile in una sola "sessione" ogni anno, nel periodo estivo. La sessione termina nella data specificata sulla <Link href="/about" onClick={pushState.linkHandler}>pagina di informazioni</Link>. Durante questa giornata, tutti i dati salvati su questo sito verranno cancellati, ad eccezione dei dati statistici indicati sotto.</p>
          <p>I dati salvati sul proprio dispositivo non verranno cancellati. È possibile cancellare questi dati dalle impostazioni del proprio browser.</p>
        </Text>
        <Text variant="mediumPlus"><h2>Statistiche</h2></Text>
        <Text variant="medium">
          <p>Questo sito utilizza un servizio di terze parti (Plausible Analytics) per raccogliere informazioni su come viene utilizzato Liber. Questo servizio di terze parti raccoglie solo dati anonimi, non raccoglie dati personali.</p>
          <p>Quando Liber è disponibile, le seguenti informazioni vengono generate e salvate una volta ogni giorno:</p>
          <ul>
            <li>numero totale di utenti</li>
            <li>numero totale di inserzioni attive</li>
            <li>numero totale di sessioni attive</li>
          </ul>
          <p>Se si cancella il proprio account, questo non avrà effetto sui dati statistici salvati precedentemente alla cancellazione. (Dal giorno successivo verrà contato un utente in meno, ma non verrà ridotto il numero di utenti per i dati dei giorni precedenti.)</p>
          <p>Questi dati potrebbero inoltre venire conservati oltre la fine della sessione.</p>
          <p>Cloudflare (un servizio di terze parti utilizzato da Liber) raccoglie alcune statistiche basate sulle richieste HTTP (transito di informazioni tra Liber e il proprio dispositivo).</p>
        </Text>
        <Text variant="mediumPlus"><h2>Utilizzo di cookie e tecnologie simili su questo sito</h2></Text>
        <Text variant="medium">
          <p>Questo sito utilizza solo cookie tecnici. Le informazioni di accesso vengono salvate nel local/sessionStorage (tecnologie simili).</p>
          <p>Alcuni servizi di terze parti (nello specifico, Cloudflare) possono aggiungere altri cookie al proprio browser.</p>
          <p>I cookie e dati local/sessionStorage di <i>marmaluot Liber</i> sono associati ai seguenti domini:</p>
          <ul>
            <li>liber.marmaluot.com</li>
            <li>donwana.altervista.org</li>
          </ul>
        </Text>
        <Text variant="mediumPlus"><h2>Utilizzo delle immagini dalla webcam</h2></Text>
        <Text variant="medium">
          <p>La funzione "Scansiona codice a barre" permette di scansionare il codice a barre presente su un libro di testo per rilevarne il suo codice ISBN.</p>
          <p>Le immagini della webcam/videocamera vengono raccolte dal proprio dispositivo ed elaborati all'interno di esso. Queste immagini non vengono trasmesse a Liber o a servizi di terze parti da esso utilizzati.</p>
          <p>Una volta scansionato il codice ISBN, il solo codice (non l'immagine dove il codice è stato trovato) verrà inviato a Liber se e solo se si decide di aggiungere l'inserzione attraverso il pulsante apposito. Per maggiori informazioni su come vengono gestiti i dati delle inserzioni, vedere la sezione "Quali dati vengono raccolti?"</p>
        </Text>
        <Text variant="mediumPlus"><h2>Utilizzo dei dati di posizione</h2></Text>
        <Text variant="medium">
          <p>La funzione "Trova libri vicino a te" permette di ordinare le inserzioni visualizzate in base alla posizione dell'utente.</p>
          <p>I dati di posizione vengono raccolti dal proprio dispositivo ed elaborati all'interno di esso. Le informazioni di posizione non vengono trasmesse a Liber o a servizi di terze parti da esso utilizzati.</p>
          <p>L'indirizzo IP è parte essenziale del sistema Internet, e potrebbe essere associato ad una posizione molto approssimativa. Liber effettua questo tipo di associazione solamente con gli indirizzi IP contenuti nelle segnalazioni inviate dalla schermata "Errore inaspettato".</p>
          <p>Gli utenti registrati possono aggiungere un comune come parte del profilo. Per maggiori informazioni su come vengono gestiti i dati del profilo, vedere la sezione "Quali dati vengono raccolti?"</p>
        </Text>
        <Text variant="mediumPlus"><h2>Collegamento account Telegram e bot</h2></Text>
        <Text variant="medium">
          <h3>Utenti che inviano messaggi al bot Telegram</h3>
          <p>Quando si invia un messaggio al bot, Liber riceve tutte le informazioni sul messaggio che sono visibili nell'applicazione insieme a informazioni sull'utente che ha inviato il messaggio ed il contenuto del messaggio stesso.</p>
          <p>Questi dati non vengono conservati per un tempo superiore a quanto necessario per evadere la richiesta contenuta nel messaggio, rendersi conto che non c'è niente da fare, o inviare una risposta indicando la non comprensione della richiesta.</p>
          <h3>Utenti che visualizzano il contatto Telegram di un utente</h3>
          <p>Quando si tenta di contattare un utente attraverso Liber, il bot riceve un messaggio particolare generato attraverso la procedura di <Link href="https://core.telegram.org/bots#deep-linking" target="_blank" rel="noopener noreferrer">deep linking</Link> ed inviato dall'utente contattante, perciò si applica quanto indicato sopra. Il messaggio contiene le seguenti informazioni:</p>
          <ul>
            <li>Operazione selezionata (avvia bot visualizzando un contatto utente)</li>
            <li>ID dell'utente di cui si desidera visualizzare il contatto</li>
          </ul>
          <h3>Utenti registrati che collegano un account Telegram</h3>
          <p>Liber permette agli utenti di collegare un account Telegram come metodo di contatto alternativo.</p>
          <p>Quando viene collegato un account Telegram Liber riceve i seguenti dati:</p>
          <ul>
            <li>nome dell'account Telegram</li>
            <li>nome utente (se presente)</li>
            <li>URL presso cui è possibile recuperare l'immagine del profilo Telegram</li>
            <li>ID utente</li>
            <li>data e ora in cui è stata effettuata l'autenticazione con Telegram per collegare l'account a Liber</li>
            <li>firma elettronica dei dati soprastanti (<Link href="https://core.telegram.org/widgets/login#checking-authorization" target="_blank" rel="noopener noreferrer">info tecniche sul sito web di Telegram</Link>)</li>
          </ul>
          <p>Questi dati vengono ricevuti nel proprio browser, quindi trasmessi a Liber per completare il collegamento dell'account.</p>
          <p>Liber riceve tutti i dati di cui sopra, e li elabora per permettere la verifica della firma elettronica e per verificare che i dati non siano troppo vecchi.</p>
          <p>Liber riceve un riferimento all'immagine del profilo, ma quest'ultima non viene mai scaricata.</p>
          <p>Durante questa procedura viene inviato un messaggio di conferma all'utente per verificare se è stato concesso il consenso all'invio di messaggi.</p>
          <p>Una volta verificata la data, la firma ed il consenso all'invio di messaggi, l'unica informazione che viene conservata permanentemente è l'ID utente (si veda anche la sezione "Quali dati vengono raccolti?")</p>
          <p>Se l'utente blocca il bot, l'account Telegram resterà collegato al profilo Liber ma gli eventuali visitatori che tentano di contattare l'utente riceveranno un messaggio di errore invece del contatto richiesto.</p>
          <h3>Invio di messaggi esterni ad un flusso iniziato dall'utente</h3>
          <p>Il bot può inviare messaggi che non sono in risposta ad un'operazione iniziata dall'utente (attraverso l'invio di un messaggio o altre operazioni simili) solo per inviare il contatto di un utente che ha tentato di visualizzare il contatto del primo utente.</p>
          <p>Questi messaggi possono essere interrotti scollegando il proprio account Telegram dal profilo Liber nella sezione "Gestione account" di questo sito oppure inviando un messaggio contentente il testo "/unlink" al bot e seguendo eventuali istruzioni aggiuntive.</p>
        </Text>
        <Text variant="mediumPlus"><h2>Accesso OAuth</h2></Text>
        <Text variant="medium">
          <p>Questo sito utilizza la tecnologia <Link href="https://oauth.net/2/" target="_blank">OAuth 2.0</Link> per permettere l'accesso con account di altri siti.</p>
          <p>I token di accesso vengono utilizzati solo durante la procedura di accesso e non vengono salvati.</p>
          <p>Dove possibile, <i>marmaluot Liber</i> chiede ai servizi di ricevere solamente il nome completo e l'indirizzo email dell'utente, ma queste informazioni potrebbero essere accompagnate da altri dati (<Link href="https://developers.facebook.com/docs/graph-api/reference/user" target="_blank">Facebook</Link>, <Link href="https://developers.google.com/identity/protocols/oauth2/openid-connect" target="_blank">Google</Link>, <Link href="https://docs.microsoft.com/it-it/graph/api/resources/user" target="_blank">Microsoft</Link>), che non vengono utilizzati né salvati.</p>
        </Text>
        <Text variant="mediumPlus"><h2>Servizi di terze parti</h2></Text>
        <Text variant="medium">
          <p>Questo sito utilizza vari servizi di terze parti: (ogni nome è un link alla rispettiva privacy policy / data policy)</p>
          <ul>
            <li><Link href="https://www.iubenda.com/privacy-policy/8267552" target="_blank" rel="noopener noreferrer">Altervista</Link></li>
            <li><Link href="https://www.cloudflare.com/privacy-policy" target="_blank" rel="noopener noreferrer">Cloudflare</Link> (DNS, CDN/Proxy, Turnstile)</li>
            <li><Link href="https://www.mailgun.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Mailgun</Link></li>
            <li><Link href="https://www.netlify.com/privacy" target="_blank" rel="noopener noreferrer">Netlify</Link></li>
            <li><Link href="https://plausible.io/data-policy" target="_blank" rel="noopener noreferrer">Plausible Analytics</Link></li>
            <li><Link href="https://telegram.org/privacy" target="_blank" rel="noopener noreferrer">Telegram</Link></li>
            <li><Link href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">Facebook OAuth</Link> (solo se viene selezionato "Accedi con Facebook" nella pagina di login)</li>
            <li><Link href="https://policies.google.com/privacy?hl=it-IT" target="_blank" rel="noopener noreferrer">Google OAuth</Link> (solo se viene selezionato "Accedi con Google" nella pagina di login)</li>
            <li><Link href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" rel="noopener noreferrer">Microsoft OAuth</Link> (solo se viene selezionato "Accedi con Microsoft" nella pagina di login)</li>
          </ul>
        </Text>
        <Text variant="mediumPlus"><h2>Hai altre domande?</h2></Text>
        <Text variant="medium">
          <p>Se hai altre domande sulla privacy, contatta il creatore del sito. Trovi l'indirizzo email sul sito web <Link href="https://qlcvea.com" target="_blank" rel="noopener noreferrer">qlcvea.com</Link>.</p>
        </Text>
			</div>
		);
	}
}
