import * as React from 'react';

import { mergeStyleSets, getTheme, Text, Link } from '@fluentui/react';

import config from '../config';
import marmaluotLogo from '../marmaluot.svg';

export default class About extends React.Component {
  constructor() {
    super();
    var theme = getTheme();
    this._styles = mergeStyleSets({
      outer: {
        textAlign: 'center',
        maxWidth: 650,
        margin: 'auto',
        padding: '0 5px 0 5px'
      },
      marmaluot: {
        fontFamily: 'sans-serif',
        margin: 0,
        width: '30vw',
        maxWidth: 130,
        userSelect: 'none'
      },
      logo: {
        fontFamily: 'sans-serif',
        color: theme.palette.themePrimary,
        fontSize: '8vh',
        marginTop: 0,
        marginBottom: 30,
        userSelect: 'none'
      },
      topSpacer: {
        marginTop: 30,
        marginBottom: 0
      }
    });
  }

  _renderLicense = (license) => {
    return (<li><Text variant="medium"><b>{license.name}</b> - {license.copyright} - <Link href={license.licenseUrl} target="_blank">Licenza {license.licenseName}</Link></Text></li>);
  }

	render() {
		return (
			<div className={this._styles.outer}>
        <div className={this._styles.topSpacer}></div>
				<img className={this._styles.marmaluot} alt="marmaluot" src={marmaluotLogo} />
        <p className={this._styles.logo}><b>Liber</b></p>
        {(typeof config.server.activeSession === 'string') ? (<p><Text variant="large">Sessione {config.server.activeSession}</Text></p>) : null}
        <p><Text variant="medium">Creato da <Link href="https://qlcvea.com" target="_blank" rel="noopener noreferrer">Marco Benzoni</Link></Text></p>
        <p><Text variant="medium">Se hai domande, o sei interessato a rendere disponibile Liber nella tua scuola, <Link href="https://qlcvea.it/contact" target="_blank" rel="noopener noreferrer">contattami</Link>.</Text></p>
        <p><Text variant="medium">Se gradisci questo servizio, puoi <Link href="https://qlcvea.it/tipjar" target="_blank" rel="noopener noreferrer">lasciare una mancia</Link>.</Text></p>
        {(typeof config.server.sessionEnd === 'string') ? (<p><Text variant="medium">Il servizio è disponibile fino al {(new Date(config.server.sessionEnd)).toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' })}.</Text></p>) : null}
        <h2><Text variant="large">Licenze open source</Text></h2>
        <ul>
          {config.openSourceLicenses.map(this._renderLicense)}
          {config.server.openSourceLicenses.map(this._renderLicense)}
        </ul>
        <h2><Text variant="large">Fonte dei dati</Text></h2>
        <p><Text variant="medium">Le informazioni sulle liste dei libri di ciascuna classe presente su Liber sono generate a partire dagli <Link href="https://dati.istruzione.it/opendata/opendata/catalogo/elements1/?area=Adozioni%20libri%20di%20testo" target="_blank" rel="noopener noreferrer">Open Data sulle Adozioni dei Libri di Testo</Link> pubblicati dal Ministero dell'Istruzione.</Text></p>
        <p><Text variant="medium">
          La lista dei comuni è derivata da <Link href="https://www.wikidata.org" target="_blank" rel="noopener noreferrer">Wikidata</Link> (entità dove la proprietà P31 ha il valore Q747074).<br />
          <Link href="https://w.wiki/3agW" target="_blank" rel="noopener noreferrer">Visualizza la query utilizzata per ricavare la lista dei comuni</Link></Text></p>
			</div>
		);
	}
}
