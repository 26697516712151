import { Icon, TooltipHost, mergeStyleSets } from '@fluentui/react';
import React from 'react'
import config from '../config';

var styles = mergeStyleSets({
  link: {
    color: 'inherit',
    textDecoration: 'none',
    ':visited': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  linkedBadge: {
    cursor: 'pointer',
  },
  unlinkedBadge: {
    cursor: 'default',
  }
});

function Badge(props) {
  if (props.name === null) return null;
  var badge = null;
  if (typeof config.badges[props.name] === 'undefined') {
    badge = {
      label: 'Badge sconosciuto',
      iconName: 'StatusCircleQuestionMark'
    }
  } else {
    badge = config.badges[props.name];
  }

  var badgeContent = <Icon className={typeof badge.href === 'string' ? styles.linkedBadge : styles.unlinkedBadge} iconName={badge.iconName} />;
  if (typeof badge.href === 'string') {
    badgeContent = <a className={styles.link} href={badge.href} target="_blank" rel="noopener noreferrer">{badgeContent}</a>;
  }
  badgeContent = <TooltipHost content={badge.label}>{badgeContent}</TooltipHost>;
  return badgeContent;
}

export default Badge;