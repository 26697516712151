import * as React from 'react';

import { mergeStyleSets, getTheme, Text } from '@fluentui/react';

import marmaluotLogo from '../marmaluot.svg';

export default class Unavailable extends React.Component {
  constructor() {
    super();
    var theme = getTheme();
    this._styles = mergeStyleSets({
      outer: {
        textAlign: 'center',
        maxWidth: 600,
        margin: 'auto'
      },
      marmaluot: {
        fontFamily: 'sans-serif',
        margin: 0,
        width: '30vw',
        maxWidth: 130,
        userSelect: 'none'
      },
      logo: {
        fontFamily: 'sans-serif',
        color: theme.palette.themePrimary,
        fontSize: '8vh',
        marginTop: 0,
        marginBottom: 30,
        userSelect: 'none'
      },
      topSpacer: {
        marginTop: 30,
        marginBottom: 0
      }
    });
  }

	render() {
		return (
			<div className={this._styles.outer}>
        <div className={this._styles.topSpacer}></div>
				<img className={this._styles.marmaluot} alt="marmaluot" src={marmaluotLogo} />
        <p className={this._styles.logo}><b>Liber</b></p>
        <p><Text variant="mediumPlus">Il servizio non è disponibile in questo momento.</Text></p>
			</div>
		);
	}
}
