import config from './config';

export default function makeRequest(path, method, token, data) {
  return new Promise((resolve, reject) => {
    var headers = {};
    if (typeof token != 'undefined') {
      headers = {
        Authorization: 'Bearer ' + token
      }
    }
    fetch(config.baseUrl + path, {
      method,
      body: data,
      headers
    }).then((response) => {
      if (response.status === 204) {
        resolve();
      } else if (response.status > 299 || response.status < 200) {
        response.json().then((data) => {
          reject({
            status: response.status,
            error: (typeof data.error == 'undefined') ? data : data.error,
            errorDescription: (typeof data.error_description == 'undefined') ? null : data.error_description
          })
        }).catch(() => {
          reject(response.status);
        });
      } else {
        response.json().then((data) => {
          if (data.error) return reject(data.error);
          resolve(data);
        }).catch(reject);
      }
    }).catch(reject);
  });
}