import * as React from 'react';

import SpinnerView from './spinner-view';

import makeRequest from '../make-request';

import { Panel, PanelType, Stack, TextField, Checkbox, TooltipHost, IconButton, DetailsList, SelectionMode, Dialog, DialogType, DialogFooter, PrimaryButton, mergeStyleSets } from '@fluentui/react';
import { BrowserBarcodeReader, NotFoundException } from '@zxing/library';

export default class ListingManager extends React.Component {
  constructor() {
    super();
    this._styles = mergeStyleSets({
      videoVisible: {
        width: '100%',
        height: '100%'
      },
      videoHidden: {
        display: 'none'
      }
    });
    this._closedState = {
      loading: true,
      codeReader: null,
      firstListingDialog: false,
      listings: null,
      newIsbn: '',
      newIsbnRequired: false,
      newIsbnInvalid: false,
      newIsGift: false
    }
    this.state = this._closedState;
    this._previouslyVisible = false;
  }

  _codeReaderVideoRef = React.createRef();

  _isbnRegex = /^(97(8|9))?\d{10}$/g;

  _dateFormatter = new Intl.DateTimeFormat('it', { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' })

  _scanBarcode = () => {
    this.setState({
      loading: true
    }, async () => {
      const codeReader = new BrowserBarcodeReader();
      codeReader.decodeOnceFromConstraints({ video: { facingMode: 'environment' } }, this._codeReaderVideoRef.current).then((result) => {
        this.setState({
          loading: false,
          codeReader: null,
          newIsbn: result.getText()
        });
      }).catch((err) => {
        if (err instanceof NotFoundException) {
          this.setState({
            codeReader: null
          });
        } else {
          console.error(err);
          alert('È avvenuto un errore nella lettura. Assicurati di aver acconsentito all\'uso della videocamera e che questa non sia in uso da un\'altra applicazione.');
          this.setState({
            codeReader: null
          });
        }
      });
      this.setState({
        loading: false,
        codeReader
      });
    });
  }

  _addListing = () => {
    if (this.state.newIsbn == null) {
      return this.setState({
        newIsbnRequired: true
      });
    }
    var isbn = this.state.newIsbn.replace(/[^\d]/g, '');
    if (isbn === '') {
      return this.setState({
        newIsbnRequired: true
      });
    }
    if (!isbn.match(this._isbnRegex)) return;
    this.setState({
      loading: true
    }, () => {
      makeRequest('listings.php', 'POST', this.props.token, new URLSearchParams({
        isbn: isbn,
        type: (this.state.newIsGift) ? 1 : 0
      })).then((listings) => {
        this.props.onRequestReload();
        this.setState({
          loading: false,
          firstListingDialog: ((listings.length === 1) || this.state.firstListingDialog),
          listings,
          newIsbn: '',
          newIsbnInvalid: false,
          newIsbnRequired: false,
          newIsGift: false
        }, () => {console.log(this.state.firstListingDialog)});
      }).catch((err) => {
        if (typeof err === 'object' && typeof err.error === 'string' && err.error === 'invalid_request') {
          this.setState({
            loading: false,
            newIsbnInvalid: true,
            newIsbnRequired: false
          });
        } else {
          this.props.onAccountExpired();
        }
      });
    });
  }

  componentDidUpdate() {
    if (this.state.codeReader === null && this._codeReaderVideoRef.current) {
      this._codeReaderVideoRef.current.srcObject = null;
    }
  }

  _renderInner = () => {
    if (this.state.loading) {
      return (
        <SpinnerView />
      );
    } else if (this.state.codeReader !== null) {
      return null;
    } else {
      return (
        <React.Fragment>
          <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
            <Stack.Item order={1} align="stretch">
              <TextField label="Codice ISBN" defaultValue={this.state.newIsbn} required
                onChange={(_e, value) => {
                  this.setState({
                    newIsbn: (value === '') ? null : value,
                    newIsbnRequired: false,
                    newIsbnInvalid: false
                  });
                }}
                errorMessage={this.state.newIsbnRequired ? 'Inserire un ISBN.' : (this.state.newIsbnInvalid ? 'Non conosciamo questo ISBN.' : undefined)}
                onGetErrorMessage={(value) => {
                  var cleanValue = value.replace(/[^\d]/g, '');
                  if (cleanValue === '' || cleanValue == null) return;
                  if (!cleanValue.match(this._isbnRegex)) return 'ISBN non valido.';
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this._addListing();
                  }
                }}
              />
            </Stack.Item>
            <Stack.Item order={2} align="end">
              <Checkbox label="Regalo" checked={this.state.newIsGift} onChange={(_e, value) => { this.setState({ newIsGift: value }); }} />
            </Stack.Item>
            <Stack.Item order={3} align="end">
              <TooltipHost content="Scansiona codice a barre">
                <IconButton
                  iconProps={{
                    iconName: 'GenericScan'
                  }}
                  title="Scansiona codice a barre"
                  ariaLabel="Scansiona codice a barre"
                  onClick={this._scanBarcode}
                />
              </TooltipHost>
            </Stack.Item>
            <Stack.Item order={4} align="end">
              <TooltipHost content="Aggiungi inserzione">
                <IconButton
                  iconProps={{
                    iconName: 'Add'
                  }}
                  title="Aggiungi inserzione"
                  ariaLabel="Aggiungi inserzione"
                  onClick={this._addListing}
                />
              </TooltipHost>
            </Stack.Item>
          </Stack>
          <DetailsList
            items={this.state.listings}
            selectionMode={SelectionMode.none}
            columns={[
              {
                key: 'actions',
                name: '',
                onRender: (item) => {
                  return (<React.Fragment>
                    <TooltipHost content="Rimuovi">
                      <IconButton
                        iconProps={{
                          iconName: 'Delete'
                        }}
                        title="Rimuovi"
                        ariaLabel="Rimuovi"
                        onClick={() => {
                          this.setState({
                            loading: true
                          }, () => {
                            makeRequest('listings.php?listing=' + encodeURIComponent(item.id), 'DELETE', this.props.token).then(() => {
                              var newListings = [];
                              for (var i = 0; i < this.state.listings.length; i++) {
                                // eslint-disable-next-line eqeqeq
                                if (this.state.listings[i].id != item.id) {
                                  newListings.push(this.state.listings[i]);
                                }
                              }
                              this.props.onRequestReload();
                              this.setState({
                                loading: false,
                                listings: newListings
                              });
                            });
                          });
                        }}
                      />
                    </TooltipHost>
                    <TooltipHost content="Rinnova">
                      <IconButton
                        iconProps={{
                          iconName: 'RenewalCurrent'
                        }}
                        title="Rinnova"
                        ariaLabel="Rinnova"
                        onClick={() => {
                          this.setState({
                            loading: true
                          }, () => {
                            makeRequest('listings.php?listing=' + encodeURIComponent(item.id), 'POST', this.props.token).then((updatedListing) => {
                              var newListings = Object.assign(this.state.listings);
                              for (var i = 0; i < newListings.length; i++) {
                                // eslint-disable-next-line eqeqeq
                                if (newListings[i].id == updatedListing.id) {
                                  newListings[i] = updatedListing;
                                }
                              }
                              this.props.onRequestReload();
                              this.setState({
                                loading: false,
                                listings: newListings
                              });
                            });
                          });
                        }}
                        disabled={((item.expires * 1000) - (new Date().getTime())) >= (604880 * 1000)}
                      />
                    </TooltipHost>
                  </React.Fragment>);
                },
                maxWidth: 60
              },
              {
                key: 'bookTitle',
                name: 'Titolo',
                onRender: (item) => {
                  return item.book.title;
                }
              },
              {
                key: 'bookISBN',
                name: 'ISBN',
                onRender: (item) => {
                  return item.book.isbn;
                }
              },
              {
                key: 'type',
                name: 'Tipo',
                onRender: (item) => {
                  return (item.type === 1) ? 'Regalo' : 'Offerta'
                }
              },
              {
                key: 'expires',
                name: 'Scadenza',
                minWidth: 200,
                onRender: (item) => {
                  return this._dateFormatter.format(item.expires * 1000);
                }
              },
              {
                key: 'created',
                name: 'Creato il',
                minWidth: 200,
                onRender: (item) => {
                  return this._dateFormatter.format(item.created * 1000);
                }
              }
            ]}
            onRenderCell={(listing) => {
              return(
              <div className={this._styles.itemContent}>
                <div className={this._styles.itemName}>{listing.book.title}</div>
                <div className={this._styles.itemIndex}>{listing.book.isbn}</div>
                <div>Scadenza: {this._dateFormatter.format(new Date(listing.expires * 1000))}</div>
              </div>
              );
            }}
          />
        </React.Fragment>
      );
    }
  }

	render() {
    if (this.props.visible === false && this._previouslyVisible) {
      this.setState(this._closedState);
    }
    if (this.props.visible && !this._previouslyVisible) {
      makeRequest('listings.php', 'GET', this.props.token).then((listings) => {
        this.setState({
          loading: false,
          listings,
          newIsbn: (typeof this.props.newListingIsbn === 'string' && this.props.newListingIsbn !== null) ? this.props.newListingIsbn : ''
        });
      }).catch(() => {
        this.props.onAccountExpired();
      });
    }
    this._previouslyVisible = this.props.visible;
		return (
      <React.Fragment>
        <Dialog
          hidden={!this.state.firstListingDialog}
          onDismiss={() => {
            this.setState({
              firstListingDialog: false
            });
          }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Congratulazioni!',
            subText: 'Hai creato la tua prima inserzione! Tra tre settimane scadrà e verrà rimossa da Liber. Se vuoi mantenerla, torna qui nella gestione inserzioni non più di una settimana prima della scadenza e clicca sull\'icona del calendario (accanto al cestino). Facendo ciò, l\'inserzione verrà rinnovata e scadrà tre settimane dopo il momento in cui hai cliccato quell\'icona.',
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={() => {
              this.setState({
                firstListingDialog: false
              });
            }} text="OK" />
          </DialogFooter>
        </Dialog>
        <Panel
          isOpen={this.props.visible}
          onDismiss={(e) => {
            if (this.state.codeReader !== null) {
              e.preventDefault();
              this.state.codeReader.reset();
              this.setState({
                codeReader: null
              });
            } else if (this.state.loading || this.state.firstListingDialog) e.preventDefault();
            else this.props.onClose();
          }}
          type={PanelType.smallFluid}
          closeButtonAriaLabel={this.state.codeReader === null ? "Chiudi" : "Annulla"}
          headerText={this.state.codeReader === null ? "Gestione inserzioni" : "Scansione codice a barre"}
          isLightDismiss={!this.state.loading}
          hasCloseButton={!this.state.loading}
          styles={this.state.codeReader === null ? undefined : {
            content: {
              paddingBottom: 0,
              height: '100%'
            },
            scrollableContent: {
              height: '100%',
              overflowY: 'hidden'
            }
          }}
        >
          <React.Fragment>
            <video ref={this._codeReaderVideoRef} muted={true} className={this.state.codeReader === null ? this._styles.videoHidden : this._styles.videoVisible} />
            {this._renderInner(this.props)}
          </React.Fragment>
        </Panel>
      </React.Fragment>
		);
	}
}
