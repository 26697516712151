import * as React from 'react';

import pushState from '../push-state';

import { Dialog, DialogFooter, DialogType, PrimaryButton, DefaultButton, mergeStyleSets } from '@fluentui/react';

export default class CookieWarning extends React.Component {
  constructor() {
		super();
		this._styles = mergeStyleSets({
			main: {
				position: 'absolute',
				top: 50,
				left: 0,
        width: '100%',
        zIndex: 3000
			},
    });
  }

  static get VERSION() {
    return "2021.3";
  }

	render() {
		if (this.props.visible) {
      return (
        <Dialog
          hidden={!this.props.visible}
          onDismiss={this.props.onDismiss}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: this.props.isApp ? 'Questa app raccoglie dati' : 'Questo sito usa i cookie',
            subText: (this.props.isApp ? 'Questa app' : 'Questo sito')
              + ' usa cookie tecnici e tecnologie simili. '
              + (this.props.isApp ? 'Questa app' : 'Questo sito')
              + ' raccoglie dati statistici anonimi (utenti totali, inserzioni totali, sessioni totali) ed utilizza il servizio di statistiche Plausible Analytics.',
          }}
          
        >
          <DialogFooter>
            <PrimaryButton onClick={this.props.onDismiss} text="Continua" />
            <DefaultButton onClick={() => {
              pushState('/privacy');
              this.props.onDismiss();
            }} text="Info privacy e cookie" />
          </DialogFooter>
        </Dialog>
      );
    } else {
      return null;
    }
	}
}
