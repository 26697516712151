import * as React from 'react';

import { Dialog, DialogFooter, DialogType, PrimaryButton, DefaultButton, Text, Spinner, SpinnerSize } from '@fluentui/react';

import locations from '../locations/locations.json';
import locationIndexes from '../locations/indexes.json';

import config from '../config';

export default class LocationSort extends React.Component {
  state = {
    loading: false,
    enabled: false,
    error: null
  }

  get enabled() {
    return this.state.enabled;
  }

  _square(n) {
    return n*n;
  }

  _doSort = (originalSort) => {
    return new Promise((resolve, reject) => {
      try {
        var aGreater = -1;
        var bGreater = 1;
        var equal = 0;
        var sorted = [...originalSort].sort((a, b) => {
          // if only one has a location then it is certainly before the other
          var aHasLocation = a.user.location !== null && a.user.location !== '';
          var bHasLocation = b.user.location !== null && b.user.location !== '';
          if (aHasLocation && !bHasLocation) {
            return aGreater;
          } else if (bHasLocation && !aHasLocation) {
            return bGreater;
          }

          if (aHasLocation && bHasLocation && a.user.location !== b.user.location) {
            var aLocId = parseInt(a.user.location);
            var bLocId = parseInt(b.user.location);
            if (isNaN(aLocId)) {
              if (!isNaN(bLocId)) return bGreater;
              // if a == NaN && b != NaN => b > a. if a == NaN && b = NaN => compare dates (leave if block)
            } else if (isNaN(bLocId)) {
              // this won't be triggered if both a == NaN && b == NaN, therefore this is the a != NaN && b == NaN case, therefore a > b
              return aGreater;
            } else {
              var ai = locationIndexes.indexOf(aLocId);
              var bi = locationIndexes.indexOf(bLocId);
              if (ai === -1) {
                if (bi !== -1) return bGreater;
              } else if (bi === -1) {
                return aGreater;
              } else {
                var aLoc = locations[ai];
                var bLoc = locations[bi];
                var aDistance2 = this._square(aLoc.x - config.runtime.userPos.coords.longitude) + this._square(aLoc.y - config.runtime.userPos.coords.latitude);
                var bDistance2 = this._square(bLoc.x - config.runtime.userPos.coords.longitude) + this._square(bLoc.y - config.runtime.userPos.coords.latitude);
                // both values are the square of the distance so there's no reason to take the square root of each
                // a > b if aDistance2 < bDistance2
                if (aDistance2 < bDistance2) return aGreater;
                else if (aDistance2 > bDistance2) return bGreater;
              }
            }
          }

          // if equal location, then sort by date (oldest first)
          // a > b if a.created < b.created
          if (a.created < b.created) return aGreater;
          else if (a.created > b.created) return bGreater;
          
          return equal;
        });

        resolve(sorted);
      } catch (err) {
        reject(err);
      }
    });
  }

	render() {
    if (config.runtime.userPos !== null && !this.state.enabled) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.loading = true;
      this._doSort(this.props.originalSort).then((sorted) => {
        this.setState({
          loading: false,
          enabled: true,
        }, () => this.props.onNewSort(sorted));
      }).catch((err) => { throw err; });
    }
    if (this.state.error !== null) {
      return (
        <Dialog
          hidden={!this.props.visible}
          onDismiss={() => this.setState({
            error: null
          })}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: (this.state.error.code === window.GeolocationPositionError.PERMISSION_DENIED) ? 'Autorizzazione negata' : 'Errore',
            subText: (this.state.error.code === window.GeolocationPositionError.PERMISSION_DENIED) ?
              'Hai negato a Liber il permesso di accedere alla tua posizione, perciò non è possibile utilizzare i servizi di posizione.\n' + 
                'Se non ti è stato chiesto di fornire l\'autorizzazione, controlla le impostazioni del tuo browser.' :
              'Non è stato possibile trovare la tua posizione.'
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={() => this.setState({
              error: null
            })} text="OK" />
          </DialogFooter>
        </Dialog>
      );
    } else if (this.props.visible) {
      return (
        <Dialog
          hidden={!this.props.visible}
          onDismiss={this.state.loading ? null : this.props.onDismiss}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Trova libri vicino a te',
            subText:
              this.state.enabled ?
              'Disattivando i servizi di posizione, le inserzioni non saranno più ordinate in base alla tua distanza dagli utenti a cui appartengono.' :
              'Attivando i servizi di posizione, le inserzioni verranno ordinate in base alla tua distanza dagli utenti a cui appartengono. Questo processo avviene sul tuo dispositivo, la posizione non viene inviata al di fuori di esso.',
          }}
        >
          {(config.runtime.userPos === null) ? null :
            <Text variant="medium">
              <p>Posizione rilevata:</p>
              <ul>
                <li><b>Longitudine</b>: {config.runtime.userPos.coords.longitude}</li>
                <li><b>Latitudine</b>: {config.runtime.userPos.coords.latitude}</li>
                <li><b>Precisione (m)</b>: {config.runtime.userPos.coords.accuracy}</li>
                <li><b>Comune più vicino</b>: {(() => {
                  var closest = locations[0];
                  var closestDistance2 = this._square(locations[0].x - config.runtime.userPos.coords.longitude) + this._square(locations[0].y - config.runtime.userPos.coords.latitude);
                  for (var loc of locations) {
                    var distance2 = this._square(loc.x - config.runtime.userPos.coords.longitude) + this._square(loc.y - config.runtime.userPos.coords.latitude);
                    if (distance2 < closestDistance2) {
                      closest = loc;
                      closestDistance2 = distance2;
                    }
                  }
                  return closest.n + ' (' + closest.p + ')';
                })()}</li>
              </ul>
            </Text>
          }
          <DialogFooter>
            <PrimaryButton onClick={() => {
              return new Promise((resolve, reject) => {
                try {
                  var wasEnabled = !!this.state.enabled;
                  this.setState({
                    loading: true
                  }, () => {
                    try {
                      window.navigator.geolocation.getCurrentPosition((userPos) => {
                        config.runtime.userPos = userPos;
                        this._doSort(this.props.originalSort).then((sorted) => {
                          this.setState({
                            loading: false,
                            enabled: true
                          }, () => {
                            this.props.onNewSort(sorted);
                            if (!wasEnabled) this.props.onDismiss();
                            resolve();
                          });
                        }).catch(reject);
                      }, (error) => {
                        console.error(error);
                        this.setState({
                          loading: false,
                          error
                        }, resolve);
                      }, {
                        maximumAge: 0
                      });
                    } catch (err) {
                      reject(err);
                    }
                  });
                } catch (err) {
                  reject(err);
                }
              });
            }} disabled={this.state.loading}>{this.state.enabled ? 'Aggiorna posizione' : 'Attiva'} {this.state.loading ? <Spinner size={SpinnerSize.small} /> : null}</PrimaryButton>
            {this.state.enabled ?
              <DefaultButton onClick={() => {
                config.runtime.userPos = null;
                this.setState({
                  enabled: false
                }, () => {
                  this.props.onNewSort([...this.props.originalSort]);
                  this.props.onDismiss();
                });
              }} disabled={this.state.loading} text="Disattiva" />
            : null}
            <DefaultButton onClick={this.props.onDismiss} text={this.state.enabled ? 'Chiudi' : 'Annulla'} disabled={this.state.loading} />
          </DialogFooter>
        </Dialog>
      );
    } else {
      return null;
    }
	}
}
