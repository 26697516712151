import * as React from 'react';

import { mergeStyleSets, Spinner, SpinnerSize } from '@fluentui/react';

export default class SpinnerView extends React.Component {
  constructor() {
    super();
    this._styles = mergeStyleSets({
      outer: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }
    });
  }

	render() {
		return (
			<div className={this._styles.outer}>
				<Spinner size={SpinnerSize.large} />
			</div>
		);
	}
}
