import * as React from 'react';

import { mergeStyleSets, Dialog, DialogType, DialogFooter, PrimaryButton } from '@fluentui/react';

import About from './about';
import AccountManager from './account-manager';
import BookSearch from './book-search';
import ClassroomSelector from './classroom-selector';
import CookieWarning from './cookie-warning';
import Error404 from './error404';
import ErrorHandler from './error-handler';
import Header from './header';
import ListingManager from './listing-manager';
import ListingViewer from './listing-viewer';
import Privacy from './privacy';
import SchoolSelector from './school-selector';
import SectorSelector from './sector-selector';
import SpinnerView from './spinner-view';
import Unavailable from './unavailable';

import config from '../config';
import makeRequest from '../make-request';
import Welcomer from './welcomer';

export default class App extends React.Component {
	constructor() {
		super();
		this._styles = mergeStyleSets({
			outer: {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				minHeight: '100%'
			},
			inner: {
				position: 'absolute',
				top: 50,
				left: 0,
				width: '100%',
				minHeight: 'calc(100% - 50px)'
			}
		});
		this.state = {
			available: true,
			mode: 'browse',
			token: null,
			email: null,
			school: null,
			schools: null,
			sector: null,
			sectors: null,
			classroom: null,
			classrooms: null,
			static: null,
			book: false,
			loading: true,
			servConf: null,
			cookieInfoVisible: false,
			welcomerVisible: false,
			actionResponse: null,
			newListingIsbn: null,
		}

		if (typeof window == 'undefined') {
			this.state.loading = true;
			// prerender: force loading state to true and do nothing else
		} else {
			// eslint-disable-next-line eqeqeq
			this.state.cookieInfoVisible = !(window.localStorage.getItem('liber-cookie') == CookieWarning.VERSION);
			// eslint-disable-next-line eqeqeq
			this.state.welcomerVisible = !(window.localStorage.getItem('liber-welcomer') == Welcomer.VERSION);
			var currentURL = new URL(window.location.toString());

			var useSearchParamsToken = true;
			if (currentURL.searchParams.get('action')) {
				useSearchParamsToken = false;
				makeRequest('actions.php', 'POST', undefined, currentURL.searchParams).then((data) => {
					this.setState({
						actionResponse: data
					});
				}).catch((err) => {
					console.error(err);
					this.setState({
						actionResponse: {
							success: false,
							message: 'Operazione fallita.'
						}
					});
				});
				currentURL.search = '';
				window.history.replaceState(null, window.document.title, currentURL);
			}

			if (useSearchParamsToken && currentURL.searchParams.get('token')) {
				this.state.token = currentURL.searchParams.get('token');
				currentURL.searchParams.delete('token');
				window.history.replaceState(null, window.document.title, currentURL);
			} else {
				var accountStr = window.localStorage.getItem('liber');
				if (typeof accountStr == 'string' && accountStr != null) {
					try {
						var account = JSON.parse(accountStr);
					} catch (err) {
						return;
					}
					if (typeof account == 'object' && typeof account.token == 'string' && typeof account.email == 'string') {
						this.state.token = account.token;
						this.state.email = account.email;
					}
				}
			}
		}
	}

	_processHash = () => {
		var hash = window.location.pathname.toLowerCase();
		if (hash.startsWith('/')) {
			hash = hash.substr(1);
		}
		if (hash.endsWith('/')) {
			hash = hash.substr(0, hash.length - 1);
		}
		if (hash.startsWith('schools/')) {
			this.setState({
				loading: true
			}, () => {
				var schoolId = hash.substr('schools/'.length);
				makeRequest('schools.php?school=' + encodeURIComponent(schoolId), 'GET').then((school) => {
					this.setState({
						loading: false,
						static: null,
						book: false,
						school: school,
						sector: null,
						sectors: school.sectors,
						classroom: null,
						classrooms: null
					});
				}).catch(() => {
					this.setState({
						loading: false,
						static: '404',
						book: false
					});
				});
			});
		} else if (hash.startsWith('sectors/')) {
			this.setState({
				loading: true
			}, () => {
				var sectorId = hash.substr('sectors/'.length);
				makeRequest('sectors.php?sector=' + encodeURIComponent(sectorId), 'GET').then((sector) => {
					// eslint-disable-next-line eqeqeq
					if (this.state.school == null || this.state.school.id != sector.school.id) {
						makeRequest('schools.php?school=' + encodeURIComponent(sector.school.id), 'GET').then((school) => {
							this.setState({
								loading: false,
								static: null,
								book: false,
								school: school,
								sector: sector,
								classroom: null,
							});
						}).catch(() => {
							this.setState({
								loading: false,
								static: '404',
								book: false
							});
						});
					} else {
						this.setState({
							loading: false,
							static: null,
							book: false,
							sector: sector,
							classroom: null
						});
					}
				}).catch(() => {
					this.setState({
						loading: false,
						static: '404',
						book: false
					});
				});
			});
		} else if (hash.startsWith('classrooms/')) {
			this.setState({
				loading: true
			}, () => {
				var classroomId = hash.substr('classrooms/'.length);
				makeRequest('classrooms.php?classroom=' + encodeURIComponent(classroomId), 'GET').then((classroom) => {
					// eslint-disable-next-line eqeqeq
					if (this.state.sector == null || this.state.sector.id != classroom.sector.id) {
						makeRequest('sectors.php?sector=' + encodeURIComponent(classroom.sector.id), 'GET').then((sector) => {
							makeRequest('schools.php?school=' + encodeURIComponent(sector.school.id), 'GET').then((school) => {
								this.setState({
									loading: false,
									school: school,
									static: null,
									book: false,
									sector: sector,
									classroom: classroom
								});
							}).catch(() => {
								this.setState({
									loading: false,
									static: '404',
									book: false
								});
							});
						}).catch(() => {
							this.setState({
								loading: false,
								static: '404',
								book: false
							});
						});
					} else {
						this.setState({
							loading: false,
							static: null,
							book: false,
							classroom: classroom
						});
					}
				}).catch(() => {
					this.setState({
						loading: false,
						static: '404',
						book: false
					});
				});
			});
		} else if (hash === 'books') {
			this.setState({
				static: null,
				book: null,
				loading: false
			});
		} else if (hash.startsWith('books/')) {
			this.setState({
				loading: true
			}, () => {
				var bookId = hash.substr('books/'.length);
				makeRequest('books.php?book=' + encodeURIComponent(bookId), 'GET').then((book) => {
					this.setState({
						loading: false,
						static: null,
						book
					});
				}).catch(() => {
					this.setState({
						loading: false,
						static: '404',
						book: false
					});
				});
			});
		} else if (hash === 'privacy') {
			this.setState({
				static: 'privacy',
				book: false,
				loading: false
			});
		} else if (hash === 'about') {
			this.setState({
				static: 'about',
				book: false,
				loading: false
			});
		} else if (hash === '') {
			this.setState({
				static: null,
				book: false,
				school: null,
				loading: null
			});
		} else {
			this.setState({
				static: '404',
				book: false,
				loading: false
			});
		}
	}

	_accountExpired = () => {
		this.setState({
			token: null,
			email: null
		}, () => {
			window.localStorage.removeItem('liber');
			this.setState({ mode: 'browse' });
		})
	}

	_loadingDone = (servConf) => {
		window.addEventListener('message', (e) => {
			if (e.origin.toLowerCase() === config.messageOrigin) {
				if (typeof e.data.token == 'string' && typeof e.data.email == 'string') {
					this.setState({
						token: e.data.token,
						email: e.data.email.toLowerCase()
					}, () => {
						window.localStorage.setItem('liber', JSON.stringify({
							token: e.data.token,
							email: e.data.email.toLowerCase()
						}));
						if (typeof this._authCallback === 'function') {
							this._authCallback();
							this._authCallback = null;
						}
					});
				} else if (typeof e.data.dataMgrTokenRequest != 'undefined' && e.data.dataMgrTokenRequest) {
					e.source.postMessage({
						token: JSON.parse(window.localStorage.getItem('liber')).token
					}, config.messageOrigin)
				}
			}
		});
		window.addEventListener('popstate', this._processHash);
		window.addEventListener('--liber-state', this._processHash)
		config.server = servConf;
		this._processHash();
	}

	_authCallback = null;
	_authWindow = null;
	_authInterval = null;
	_attemptAuth = (source, callback) => {
		if (this._authWindow !== null && !this._authWindow.closed) this._authWindow.close();
		this._authWindow = window.open(config.baseUrl + 'auth.php' + ((typeof source === 'string' && source !== null) ? '?source=' + encodeURIComponent(source) : ''), undefined, 'width=600,height=500,menubar=off,toolbar=off,location=off,status=off,resizable=on,scrollbars=on');
		this._authInterval = setInterval(() => {
			if (this._authWindow === null || this._authWindow.closed) {
				this._authWindow = null;
				clearInterval(this._authInterval);
				this._authInterval = null;
			}
		}, 100);
		if (typeof callback === 'function') this._authCallback = callback;
		else this._authCallback = null;
	}

	_requireAuth = (source, callback) => {
		if (this.state.token === null) {
			this._attemptAuth(source, callback);
		} else {
			if (typeof callback === 'function') callback();
		}
	}

	_renderInner = () => {
		if (config.server == null) {
			makeRequest('config.php', 'GET').then((servConf) => {
				if (!servConf.available) {
					window.localStorage.removeItem('liber');
					this.setState({
						available: false,
						token: null,
						email: null
					});
					this._loadingDone(servConf);
				} else {
					makeRequest('schools.php', 'GET').then((schools) => {
						// eslint-disable-next-line react/no-direct-mutation-state
						this.state.schools = schools;
						if (this.state.token != null) {
							makeRequest('profile.php', 'GET', this.state.token).then((profile) => {
								config.runtime.users[profile.id] = profile;
								// eslint-disable-next-line react/no-direct-mutation-state
								this.state.email = profile.email.toLowerCase();
								var validToken = this.state.token;
								if (profile.newToken) {
									validToken = profile.newToken
								}
								// eslint-disable-next-line react/no-direct-mutation-state
								this.state.token = validToken;
								window.localStorage.setItem('liber', JSON.stringify({
									token: validToken,
									email: profile.email.toLowerCase()
								}));
								this._loadingDone(servConf);
							}).catch(() => {
								window.localStorage.removeItem('liber');
								// eslint-disable-next-line react/no-direct-mutation-state
								this.state.token = null;
								// eslint-disable-next-line react/no-direct-mutation-state
								this.state.email = null;
								this._loadingDone(servConf);
							});
						} else {
							this._loadingDone(servConf);
						}
					}).catch(() => {
						alert("errore di connessione");
					});
				}
			});
		}
		if (this.state.loading) {
			return (
				<SpinnerView />
			);
		}
		if (this.state.static === 'privacy') {
			return (
				<Privacy />
			);
		} else if (this.state.static === 'about') {
			return (
				<About />
			);
		}
		if (!this.state.available) {
			return (
				<Unavailable />
			)
		}
		if (this.state.static === '404') {
			return (
				<Error404 />
			);
		}
		if (this.state.book !== false) {
			return (
				<BookSearch book={this.state.book} onCreateListing={() => {
					this._requireAuth('create-listing', () => {
						this.setState({
							mode: 'listing',
							newListingIsbn: this.state.book.isbn.toString()
						});
					});
				}} />
			)
		}
		if (this.state.school == null) {
			return (
				<SchoolSelector schools={this.state.schools} />
			);
		} else if (this.state.sector == null) {
			return (
				<SectorSelector
					schools={this.state.schools}
					school={this.state.school}
				/>
			);
		} else if (this.state.classroom == null) {
			return (
				<ClassroomSelector
					schools={this.state.schools}
					school={this.state.school}
					sector={this.state.sector}
				/>
			);
		} else {
			return (
				<ListingViewer
					schools={this.state.schools}
					school={this.state.school}
					sector={this.state.sector}
					classroom={this.state.classroom}
					onCreateListing={(isbn) => {
						this._requireAuth('create-listing', () => {
							this.setState({
								mode: 'listing',
								newListingIsbn: isbn.toString()
							});
						});
					}}
				/>
			);
		}
	}

	render() {
		if (this.state.mode !== 'listing') {
			// eslint-disable-next-line react/no-direct-mutation-state
			this.state.newListingIsbn = null;
		}
		return (
			<div className={this._styles.outer}>
				<ErrorHandler />
				<Header email={this.state.email} profileEnabled={config.server != null && this.state.available} onAccountClick={() => { this.setState({ mode: 'account' }) }} onLogin={this._attemptAuth} />
				<Welcomer visible={!this.state.cookieInfoVisible && this.state.welcomerVisible && this.state.available} onDismiss={() => {
					this.setState({
						welcomerVisible: false
					}, () => {
						window.localStorage.setItem('liber-welcomer', Welcomer.VERSION);
					})
				}} />
				<CookieWarning visible={this.state.cookieInfoVisible} isApp={this.props.isApp} onDismiss={() => {
					this.setState({
						cookieInfoVisible: false
					}, () => {
						window.localStorage.setItem('liber-cookie', CookieWarning.VERSION);
					})
				}} />
				{this.state.actionResponse != null && <Dialog
          hidden={false}
          onDismiss={() => this.setState({ actionResponse: null })}
          dialogContentProps={{
            type: DialogType.close,
            title: this.state.actionResponse.success ? 'Operazione riuscita' : 'Operazione non riuscita',
            subText: this.state.actionResponse.message,
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={() => this.setState({ actionResponse: null })} text="Chiudi" />
          </DialogFooter>
        </Dialog>}
				<AccountManager
					visible={this.state.mode === 'account'}
					token={this.state.token}
					onEmailChange={(email) => { this.setState({ email }) }}
					onClose={() => { this.setState({ mode: 'browse' }) }}
					onListingManagerActivate={() => { this.setState({ mode: 'listing' }) }}
					onRequestReload={this._processHash}
					onAccountExpired={this._accountExpired}
				/>
				<ListingManager
					visible={this.state.mode === 'listing'}
					token={this.state.token}
					newListingIsbn={this.state.newListingIsbn}
					onClose={() => { this.setState({ mode: 'browse' }) }}
					onRequestReload={this._processHash}
					onAccountExpired={this._accountExpired}
				/>
				<div className={this._styles.inner}>
					{this._renderInner()}
				</div>
			</div>
		);
	}
}
