import * as React from 'react';

import { Dialog, DialogFooter, DialogType, PrimaryButton, Text, Link, mergeStyleSets } from '@fluentui/react';

export default class Welcomer extends React.Component {
  constructor() {
		super();
		this._styles = mergeStyleSets({
			main: {
				position: 'absolute',
				top: 50,
				left: 0,
        width: '100%',
        zIndex: 3000
			},
    });
  }

  static get VERSION() {
    return new Date().getUTCFullYear();
  }

	render() {
		if (this.props.visible) {
      return (
        <Dialog
          hidden={!this.props.visible}
          onDismiss={this.props.onDismiss}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Benvenuto in Liber!',
          }}
        >
          <Text variant="medium">
            <p>
              Su Liber puoi cercare e offrire libri di testo scolastici.
              <br />
              Se non hai libri da offrire, non c'è bisogno di creare un account!
            </p>
            <p>Per informazioni su come usare Liber, puoi <Link href="https://go.marmaluot.com/liberyt" target="_blank" rel="noopener noreferrer">consultare le videoguide</Link>.</p>
            <p>Se trovi dei problemi, non esitare a <Link href="https://go.marmaluot.com/liberfeedback" target="_blank" rel="noopener noreferrer">inviare feedback</Link>.</p>
            <p>Puoi ritrovare queste risorse selezionando la "i" in alto a destra.</p>
          </Text>
          <DialogFooter>
            <PrimaryButton onClick={this.props.onDismiss} text="Continua" />
          </DialogFooter>
        </Dialog>
      );
    } else {
      return null;
    }
	}
}
