import * as React from 'react';

import { Dialog, DialogType, Text, Link, Spinner, SpinnerSize, PrimaryButton } from '@fluentui/react';
import Turnstile from 'react-turnstile';

import config from '../config';
import makeRequest from '../make-request';

export default class ContactFetcher extends React.Component {
  state = {
    loading: false,
    user: null,
    error: null,
  }

  _previouslyVisible = false
  _contactType = "unknown";
  _telegramHref = null;

  get visible() {
    return typeof this.props.userId === 'string' && this.props.userId !== null && typeof this.props.contactType === 'string' && this.props.contactType !== null;
  }

	render() {
    if (this.visible && !this._previouslyVisible) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.error = null;
      if (typeof config.runtime.users[this.props.userId] === 'object') {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.user = config.runtime.users[this.props.userId];
      } else {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.user = null;
      }
    }
    if (this.visible) {
      this._contactType = this.props.contactType;
      if (this._contactType === 'telegram') {
        this._telegramHref = 'https://t.me/' + encodeURIComponent(config.server.telegramBotName) + '?start=' + encodeURIComponent('finduser_' + this.props.userId);
      }
    }
    this._previouslyVisible = this.visible;
    return (
      <Dialog
        hidden={!this.visible}
        onDismiss={this.state.loading ? null : this.props.onDismiss}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Contatto',
          subText: (this.state.error !== null) ? undefined : (this._contactType === 'telegram') ? 'Se hai Telegram installato, seleziona il pulsante qui sotto e poi premi "Avvia" in Telegram per contattare questo utente. Questo utente riceverà il tuo contatto Telegram.' : (this.state.user === null) ? 'Completa questo rapido controllo per visualizzare il contatto.' : this.state.user.name,
          showCloseButton: !this.state.loading,
          closeButtonAriaLabel: 'Chiudi'
        }}
      >
        {(this.state.loading ?
          <Spinner size={SpinnerSize.large} /> :
          (this.state.error !== null ? (
            <Text variant="medium">Non è stato possibile verificarti. Per favore, riprova più tardi.</Text>
          ) : (
            (this.state.user === null && this._contactType !== 'telegram' ? 
            <Turnstile
              sitekey={config.server.cfTurnstileSiteKey}
              theme="light"
              languageOverride="it"
              action="user_data"
              onVerify={(token) => this.setState({
                loading: true
              }, () => {
                makeRequest('user_data.php?user=' + encodeURIComponent(this.props.userId) + '&challenge=' + encodeURIComponent(token), 'GET').then((user) => {
                  config.runtime.users[this.props.userId] = user;
                  this.setState({
                    user,
                    loading: false
                  });
                });
              })}
              onError={(error) => {
                console.log('Turnstile verification failed', error);
                this.setState({
                  loading: false,
                  error: error
                });
              }}
            /> :
            (this._contactType === 'email' ?
              (typeof this.props.book === 'object' && this.props.book !== null ?
                <Link href={'mailto:' + encodeURIComponent(this.state.user.email) + '?subject=' + encodeURIComponent('Libro "' + this.props.book.title + '" (' + this.props.book.isbn + ')')} rel="noopener noreferrer">{this.state.user.email}</Link> :
                <Link href={'mailto:' + encodeURIComponent(this.state.user.email)} rel="noopener noreferrer">{this.state.user.email}</Link>
              ) :
              (this._contactType === 'phone' ?
                <Link href={'tel:' + encodeURIComponent('+39' + this.state.user.phone)} rel="noopener noreferrer">{this.state.user.phone}</Link> :
                (this._contactType === 'telegram' ?
                  (
                    <React.Fragment>
                      <PrimaryButton href={this._telegramHref} target="_blank" onClick={this.props.onDismiss} style={{width: '100%'}} rel="noopener noreferrer">Continua in Telegram</PrimaryButton>
                      <Text variant="small" style={{marginTop: '10px'}}><br />Il collegamento di account Telegram è sperimentale e potrebbe presentare alcune imperfezioni. Per favore, segnala eventuali problemi <Link href="https://go.marmaluot.com/liberfeedback" target="_blank" rel="noopener noreferrer">inviando feedback</Link>.</Text>
                    </React.Fragment>
                  ) :
                  <Text variant="medium">Tipologia di contatto sconosciuta.</Text>
                )
              )
            )
          )
          ))
        )}
      </Dialog>
    );
	}
}
