function pushState(path) {
  window.history.pushState(undefined, window.document.title, path);
  window.dispatchEvent(new Event('--liber-state'));
}

pushState.menuHandler = function menuHandler(ev, item) {
  if (typeof item.href !== 'string') return;
  var url = new URL(item.href, window.location.origin);
  if (url.origin.toLowerCase() === window.location.origin.toLowerCase()) {
    ev.preventDefault();
    pushState(url.href.substr(url.origin.length));
    return true;
  }
}

pushState.linkHandler = function linkHandler(ev) {
  if (typeof ev.target.href !== 'string') return;
  var url = new URL(ev.target.href, window.location.origin);
  if (url.origin.toLowerCase() === window.location.origin.toLowerCase()) {
    ev.preventDefault();
    pushState(url.href.substr(url.origin.length));
  }
}

export default pushState;