import * as React from 'react';

import ListingList from './listing-list';
import LocationSort from './location-sort';

import pushState from '../push-state';

import { mergeStyleSets, CommandBar, FocusZone, FocusZoneDirection, Link, Text, DetailsList, SelectionMode, getTheme, Panel, PanelType, TooltipHost, IconButton } from '@fluentui/react';

export default class ListingViewer extends React.Component {
  constructor() {
    super();
    var theme = getTheme();
    this._styles = mergeStyleSets({
      schoolInfo: {
        margin: 0,
        padding: 0,
        width: 'calc(100% - 10px)',
        paddingLeft: 10
      },
      outer: {
        margin: 0,
        padding: 0,
        width: '100%',
        height: 'calc(100% - (44 + 60)px)'
      },
      schoolName: [
        theme.fonts.large,
        {
          fontWeight: 'bold'
        }
      ],
      actionCell: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        display: 'flex !important',
        alignItems: 'center !important'
      },
      promoter: [
        theme.fonts.small,
        {
          color: theme.palette.neutralTertiary,
          marginBottom: 10,
        }
      ]
    });
    this._locationSortRef = React.createRef();
  }

  state = {
    locationSortVisible: false,
    bookListVisible: false,
    listings: null
  }

  _locationSortRef = null;

	render() {
    // WARNING: if this.props.classroom changes the change will not be detected!
    if (this.state.listings === null) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.listings = this.props.classroom.listings;
    }

    var commandBarItems = [
      {
        key: 'searchISBN',
        text: 'Cerca per ISBN',
        iconProps: { iconName: 'Search' },
        href: '/books',
        onClick: pushState.menuHandler
      }
    ];
    if (this.props.schools.length > 1) {
      commandBarItems.push({
        key: 'switchSchool',
        text: 'Cambia istituto',
        iconProps: { iconName: 'EMI' },
        href: '/',
        onClick: pushState.menuHandler
      });
    }
    if (this.props.school.sectors.length > 1) {
      commandBarItems.push({
        key: 'switchSector',
        text: 'Cambia indirizzo',
        iconProps: { iconName: 'WorkforceManagement' },
        href: '/schools/' + encodeURIComponent(this.props.school.id),
        onClick: pushState.menuHandler
      });
    }
    if (this.props.sector.classrooms.length > 1) {
      commandBarItems.push({
        key: 'switchClassroom',
        text: 'Cambia classe',
        iconProps: { iconName: 'Switch' },
        href: '/sectors/' + encodeURIComponent(this.props.sector.id),
        onClick: pushState.menuHandler
      });
    }
    var locationButton = {
      key: 'locationToggle',
      text: 'Trova libri vicino a te',
      iconProps: { iconName: (this._locationSortRef.current !== null && this._locationSortRef.current.enabled) ? 'Streaming' : 'StreamingOff' },
      iconOnly: true,
      onClick: () => this.setState({
        locationSortVisible: true
      })
    };
		return (
      <React.Fragment>
        <LocationSort
          ref={this._locationSortRef}
          visible={!!this.state.locationSortVisible}
          originalSort={this.props.classroom.listings}
          onDismiss={() => this.setState({
            locationSortVisible: false
          })}
          onNewSort={(listings) => {
            this.setState({
              listings
            });
          }}
        />
        <Panel
          isOpen={this.state.bookListVisible}
          isLightDismiss
          closeButtonAriaLabel='Chiudi'
          onDismiss={() => this.setState({
            bookListVisible: false
          })}
          type={PanelType.extraLarge}
          headerText={this.state.bookListVisible === 'create' ? 'Crea inserzione' : 'Lista libri'}
        >
          <React.Fragment>
            <p><Text variant='small'>{this.state.bookListVisible === 'create' ? 'Seleziona il libro per cui desideri creare un\'inserzione.' : 'Consulta il sito web d\'Istituto per visualizzare le informazioni più accurate.'}</Text></p>
            <DetailsList
              items={this.props.classroom.books}
              selectionMode={SelectionMode.none}
              columns={[
                {
                  key: 'title',
                  name: 'Titolo',
                  onRender: (item) => item.title
                },
                {
                  key: 'isbn',
                  name: 'ISBN',
                  onRender: (item) => <Link href={"/books/" + encodeURIComponent(item.id)} onClick={this.state.bookListVisible === 'create' ? (e) => e.preventDefault() || this.props.onCreateListing(item.isbn) : pushState.linkHandler}>{item.isbn}</Link>
                },
                {
                  key: 'actions',
                  name: 'Azioni',
                  className: this._styles.actionCell,
                  onRender: (item) => <TooltipHost content={this.state.bookListVisible === 'create' ? 'Seleziona' : 'Crea inserzione'}><IconButton iconProps={{ iconName: this.state.bookListVisible === 'create' ? 'Forward' : 'Add' }} title={this.state.bookListVisible === 'create' ? 'Seleziona' : 'Crea inserzione'} ariaLabel={this.state.bookListVisible === 'create' ? 'Seleziona' : 'Crea inserzione'} onClick={() => this.props.onCreateListing(item.isbn)} /></TooltipHost>
                },
              ]}
            />
          </React.Fragment>
        </Panel>
        <CommandBar
          items={commandBarItems}
          farItems={[
            {
              key: 'bookList',
              text: 'Lista libri',
              iconProps: { iconName: 'BulletedList' },
              iconOnly: true,
              onClick: () => this.setState({
                bookListVisible: true
              })
            },
            locationButton,
            {
              key: 'createListing',
              text: 'Crea inserzione',
              iconProps: { iconName: 'Add' },
              iconOnly: true,
              onClick: () => this.setState({
                bookListVisible: 'create'
              })
            }
          ]}
          ariaLabel="Usa le frecce sinistra e destra per navigare tra i comandi"
        />
        <div className={this._styles.schoolInfo}>
          <span className={this._styles.schoolName}>{this.props.school.name} - {this.props.classroom.name} {this.props.sector.name}</span><br />
          <span className={this._styles.promoter}>Promotore: <Link href={this.props.school.promoter.url} target="_blank">{this.props.school.promoter.name}</Link></span>
        </div>
        <FocusZone direction={FocusZoneDirection.vertical} className={this._styles.outer}>
          <ListingList items={this.state.listings} bookColumns={true} />
        </FocusZone>
      </React.Fragment>
		);
	}
}
