import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import App from './components/app';
import theme from './theme';
import { loadTheme, initializeIcons, registerIcons } from '@fluentui/react';
import { TelegramLogo } from './icons/telegram-logo';
loadTheme(theme);
initializeIcons();
registerIcons({
  icons: {
    'TelegramLogo': <TelegramLogo />
  }
});

ReactDOM.render(
  <App isApp={(new URL(window.location)).searchParams.get('source') === 'pwa'} />,
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}