import * as React from 'react';

import { Link, SelectionMode, DetailsList, IconButton, TooltipHost, mergeStyleSets, getTheme } from '@fluentui/react';

import locations from '../locations/locations.json';
import locationIndexes from '../locations/indexes.json';
import pushState from '../push-state';
import ContactFetcher from './contact-fetcher';
import Badge from './badge';

export default class ListingList extends React.Component {
  constructor(props) {
    super(props);
    var theme = getTheme();
    this.state = {
      contactUser: null,
      contactType: null,
      contactBook: null
    }
    this._styles = mergeStyleSets({
      contactsCell: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        display: 'flex !important',
        alignItems: 'center !important'
      },
      noResults: [
        theme.fonts.small,
        {
          color: theme.palette.neutralSecondary,
          textAlign: 'center',
        }
      ]
    });
  }

	render() {
    var bookCols = [
      {
        key: 'bookTitle',
        name: 'Titolo',
        minWidth: 200,
        onRender: (item) => {
          return item.book.title;
        }
      },
      {
        key: 'bookISBN',
        name: 'ISBN',
        maxWidth: 100,
        onRender: (item) => {
          return (
            <Link href={"/books/" + encodeURIComponent(item.book.id)} onClick={pushState.linkHandler}>{item.book.isbn}</Link>
          );
        }
      }
    ];
    var otherCols = [
      {
        key: 'name',
        name: 'Nome',
        minWidth: 150,
        onRender: (item) => {
          if (item.user.badge == null) {
            return item.user.name;
          } else {
            return (
              <>
                {item.user.name} <Badge name={item.user.badge} />
              </>
            );
          }
        }
      },
      {
        key: 'type',
        name: 'Tipo',
        maxWidth: 100,
        onRender: (item) => {
          return (item.type === 1) ? 'Regalo' : 'Offerta'
        }
      },
      {
        key: 'contacts',
        name: 'Contatti',
        className: this._styles.contactsCell,
        onRender: (item) => {
          var book = null;
          if (typeof item.book == 'object') {
            book = item.book;
          } else if (typeof this.props.book == 'object') {
            book = this.props.book;
          }
          return (
            <React.Fragment>
              {item.user.contacts.indexOf('email') !== -1 ?
                <TooltipHost content="Email">
                  <IconButton iconProps={{ iconName: 'Mail' }} title="Visualizza indirizzo email" ariaLabel="Visualizza indirizzo email" onClick={() => this.setState({
                    contactUser: item.user.id,
                    contactType: 'email',
                    contactBook: book
                  })} />
                </TooltipHost>
              : null}
              {item.user.contacts.indexOf('phone') !== -1 ?
                <TooltipHost content="Telefono">
                  <IconButton iconProps={{ iconName: 'Phone' }} title="Visualizza numero di telefono" ariaLabel="Visualizza numero di telefono" onClick={() => this.setState({
                    contactUser: item.user.id,
                    contactType: 'phone',
                    contactBook: book
                  })} />
                </TooltipHost>
              : null}
              {item.user.contacts.indexOf('telegram') !== -1 ?
                <TooltipHost content="Telegram">
                  <IconButton iconProps={{ iconName: 'TelegramLogo' }} title="Contatta via Telegram" ariaLabel="Contatta via Telegram" onClick={() => this.setState({
                    contactUser: item.user.id,
                    contactType: 'telegram',
                    contactBook: book
                  })} />
                </TooltipHost>
              : null}
            </React.Fragment>
          );
        }
      },
      {
        key: 'location',
        name: 'Luogo',
        minWidth: 200,
        onRender: (item) => {
          var location = null;
          if (item.user.location !== '' && item.user.location !== null) {
            var intKey = parseInt(item.user.location);
            if (!isNaN(intKey)) {
              var i = locationIndexes.indexOf(intKey);
              if (i !== -1) {
                location = locations[i];
              }
            }
          }
          return (location == null) ? '<sconosciuto>' : location.n + ' (' + location.p + ')';
        }
      }
    ];
    var columns = [];
    if (this.props.bookColumns) {
      for (var bookCol of bookCols) columns.push(bookCol);
    }
    for (var otherCol of otherCols) columns.push(otherCol);
		return (
      <React.Fragment>
        <ContactFetcher
          userId={this.state.contactUser}
          contactType={this.state.contactType}
          book={this.state.contactBook}
          onDismiss={() => this.setState({
            contactUser: null,
            contactType: null
          })}
        />
        <DetailsList
          items={this.props.items}
          selectionMode={SelectionMode.none}
          columns={columns}
        />
        {this.props.items.length === 0 && <p className={this._styles.noResults}>Al momento non è presente alcuna inserzione. Riprova più tardi.</p>}
      </React.Fragment>
		);
	}
}
