import * as React from 'react';

import pushState from '../push-state';

import SpinnerView from './spinner-view';

import { mergeStyleSets, CommandBar, FocusZone, FocusZoneDirection, List, Link, getFocusStyle, getTheme, Icon, getRTL } from '@fluentui/react';

export default class SchoolSelector extends React.Component {
  constructor() {
    super();
    var theme = getTheme();
    this._styles = mergeStyleSets({
      outer: {
        position: 'absolute',
        top: 44,
        left: 0,
        margin: 0,
        padding: 0,
        width: '100%',
        height: 'calc(100% - 44px)'
      },
      cellOuter: [
        getFocusStyle(theme, { inset: -1 }),
        {
          minHeight: 54,
          padding: 10,
          boxSizing: 'border-box',
          borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
          display: 'flex',
          selectors: {
            '&:hover': { background: theme.palette.neutralLight },
          },
          cursor: 'pointer'
        },
      ],
      cellInner: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
      },
      name: [
        theme.fonts.xLarge,
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ],
      promoter: [
        theme.fonts.small,
        {
          color: theme.palette.neutralTertiary,
          marginBottom: 10,
        }
      ],
      chevron: {
        alignSelf: 'center',
        marginLeft: 10,
        color: theme.palette.neutralTertiary,
        fontSize: theme.fonts.large.fontSize,
        flexShrink: 0,
      }
    });
  }

	render() {
    if (this.props.schools.length === 1) {
      pushState('/schools/' + encodeURIComponent(this.props.schools[0].id));
      return (<SpinnerView />);
    }
    var commandBarItems = [
      {
        key: 'searchISBN',
        text: 'Cerca per ISBN',
        iconProps: { iconName: 'Search' },
        href: '/books',
        onClick: pushState.menuHandler
      }
    ];
		return (
      <React.Fragment>
        <CommandBar
          items={commandBarItems}
          ariaLabel="Usa le frecce sinistra e destra per navigare tra i comandi"
        />
        <FocusZone direction={FocusZoneDirection.vertical} className={this._styles.outer}>
          <List items={this.props.schools} onRenderCell={(school) => {
            return (
              <div className={this._styles.cellOuter} data-is-focusable={true} onClick={() => {
                pushState('/schools/' + encodeURIComponent(school.id));
              }}>
                <div className={this._styles.cellInner}>
                  <div className={this._styles.name}>{school.name}</div>
                  <div className={this._styles.promoter}>Promotore: <Link href={school.promoter.url} target="_blank" rel="noopener noreferrer">{school.promoter.name}</Link></div>
                </div>
                <Icon className={this._styles.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
              </div>
            );
          }} />
        </FocusZone>
      </React.Fragment>
		);
	}
}
