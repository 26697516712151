import * as React from 'react';

import { mergeStyleSets, Text } from '@fluentui/react';

export default class Error404 extends React.Component {
  constructor() {
    super();
    this._styles = mergeStyleSets({
      outer: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 0,
        padding: '0 5px 0 5px',
        width: 'calc(100% - 10px)',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center'
      }
    });
  }

	render() {
		return (
			<div className={this._styles.outer}>
				<Text variant="xxLarge">Questa pagina non esiste</Text>
        <Text variant="medium">Nota bene: I link degli istituti, degli indirizzi/plessi, e delle classi cambiano per ogni sessione di Liber.</Text>
			</div>
		);
	}
}
